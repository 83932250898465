.journey {
  width: 100%;
  max-width: 676px;
  padding: 24px 0px 36px 0px;
  display: flex;
  flex-direction: column;

  margin: 0 auto;

  @media only screen and (max-width: 744px) {
    padding: 24px 24px 36px 24px;
  }

  @media only screen and (max-width: 430px) {
    padding: 24px 16px 36px 16px;
  }

  @media only screen and (max-width: 320px) {
    padding: 24px 16px 36px 16px;
  }

  .journey-titles-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0;

    .journey-title {
      p {
        color: var(--primary-color);
        font-family: Baloo;
        font-size: 28px;
        font-weight: 400;
        line-height: 34px; /* 121.429% */
        letter-spacing: 0.22px;

        span {
          color: var(--cta-color);
          font-family: Baloo;
          font-size: 28px;
          font-weight: 400;
          line-height: 34px;
          letter-spacing: 0.22px;
        }
      }
    }

    .journey-subtitle {
      color: var(--primary-color);
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.18px;
    }

    @media only screen and (max-width: 430px) {
      .journey-title {
        p {
          font-size: 26px;
          font-weight: 400;
          line-height: 30px; /* 115.385% */
          letter-spacing: 0.22px;

          // span {
          //   color: var(--cta-color);
          //   font-family: Baloo;
          //   font-size: 28px;
          //   font-weight: 400;
          //   line-height: 34px;
          //   letter-spacing: 0.22px;
          // }
        }
      }

      .journey-subtitle {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.13px;
      }
    }

    @media only screen and (max-width: 320px) {
      .journey-title {
        p {
          font-size: 26px;
          font-weight: 400;
          line-height: 30px; /* 115.385% */
          letter-spacing: 0.22px;

          // span {
          //   color: var(--cta-color);
          //   font-family: Baloo;
          //   font-size: 28px;
          //   font-weight: 400;
          //   line-height: 34px;
          //   letter-spacing: 0.22px;
          // }
        }
      }

      .journey-subtitle {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.13px;
      }
    }
  }

  // .journey-burn-gas-disclaimer {
  //   color: var(--secondary-color);
  //   text-align: center;
  //   font-family: Inter;
  //   font-size: 16px;
  //   font-weight: 400;
  //   line-height: 21px; /* 131.25% */
  //   letter-spacing: -0.18px;
  // }

  // ----- Mint Progress Bar ----- //

  .mint-progress {
    width: 100%;
    max-width: 376px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    gap: 16px;
    margin-top: 36px;
    margin-bottom: 36px;

    .mint-progress-outer {
      height: 6px;
      border-radius: 3px;
      background: #d9d9d9;
    }

    .mint-progress-inner {
      height: 100%;
      border-radius: 3px;
      background: var(--cta-color);
    }

    .mint-quantity {
      display: flex;
      justify-content: space-between;
      color: var(--primary-color);
      text-align: right;
      font-family: Inter;
      font-size: 15px;
      font-weight: 500;
      line-height: 15px; /* 100% */
      letter-spacing: -0.13px;
    }

    // responsive auto handled for mint-progress
  }

  // ----- Cubs Horizontal List ----- //

  .cubs {
    // 160x5 + 17x4 = 868px
    width: 868px;
    position: relative;
    right: 14%;
    margin-top: 30px;
    margin-bottom: 36px;

    .cubs-list {
      display: flex;
      gap: 17px;

      div {
        width: 160px;
        height: 160px;
        background-color: "lightgray";
        background-position: "50%";
        border-radius: 16px;
        background-size: cover;
      }
    }

    .cubs-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background-image: radial-gradient(circle, transparent 15%, white 75%);
    }

    @media only screen and (max-width: 1440px) {
      height: 160px;
      right: 14%;
    }

    @media only screen and (max-width: 744px) {
      height: 160px;
      right: 14%;

      .cubs-list {
        gap: 17px;

        div {
          width: 154px;
          height: 160px;
          background-color: "lightgray";
          background-position: 50%;
          border-radius: 16px;
          background-size: cover;
        }
      }

      .cubs-overlay {
        // background-image: radial-gradient(circle, transparent 15%, white 70%);
      }
    }

    @media only screen and (max-width: 430px) {
      width: 560px;
      height: 102px;
      right: 21%;

      .cubs-list {
        gap: 12px;

        div {
          width: 102px;
          height: 102px;
          background-color: "lightgray";
          background-position: 50%;
          border-radius: 16px;
          background-size: cover;
        }
      }

      .cubs-overlay {
        background-image: radial-gradient(circle, transparent 15%, white 70%);
      }
    }

    @media only screen and (max-width: 320px) {
      width: 330px;
      height: 102px;
      right: 7%;

      .cubs-list {
        gap: 12px;

        div {
          width: 102px;
          height: 102px;
          background-color: "lightgray";
          background-position: 50%;
          border-radius: 16px;
          background-size: cover;
        }
      }

      .cubs-overlay {
        background-image: radial-gradient(circle, transparent 15%, white 85%);
      }
    }
  }

  // ----- Community NFT Horizontal List ----- //

  .journey-else {
    width: 100%;
    max-width: 676px;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .journey-else-title {
      color: var(--primary-color);
      font-family: Baloo;
      font-size: 20px;
      font-weight: 400;
      line-height: 20px; /* 100% */
      letter-spacing: 0.22px;
    }

    .journey-else-subtitle {
      color: var(--primary-color);
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.18px;
    }

    .journey-else-collections {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 30px;

      .journey-else-collection-item {
        padding: 16px;
        border-radius: 26px;
        background: var(--quinary-color);

        .journey-else-collection-item-image {
          width: 55px;
          height: 55px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
            border: 0.5px solid var(--outline-15-color);
          }
        }

        .journey-else-collection-item-checked {
          position: absolute;
          right: -6px;
          bottom: -6px;
        }
      }
    }

    @media only screen and (max-width: 1440px) {
    }

    @media only screen and (max-width: 744px) {
      .journey-else-collections {
        justify-content: center;
        gap: 24px;
      }
    }

    @media only screen and (max-width: 430px) {
      .journey-else-collections {
        justify-content: center;
        gap: 24px;
      }
    }

    @media only screen and (max-width: 320px) {
      .journey-else-collections {
        justify-content: center;
        gap: 24px;
      }
    }
  }

  // ------------- global styles ends here ----------------- //

  // active now for all scenarios
  .journey-scenario {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .journey-mint-limit {
      color: var(--secondary-color);
      font-family: Inter;
      font-size: 15px;
      font-weight: 500;
      line-height: 15px; /* 100% */
      letter-spacing: -0.13px;
    }

    .journey-mint-progress {
      width: 376px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      gap: 16px;

      .journey-mint-progress-outer {
        height: 6px;
        border-radius: 3px;
        background: #d9d9d9;
      }

      .journey-mint-progress-inner {
        width: 100px;
        height: 100%;
        border-radius: 3px;
        background: var(--cta-color);
      }

      .journey-mint-quantity {
        display: flex;
        justify-content: space-between;
        color: var(--primary-color);
        text-align: right;
        font-family: Inter;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px; /* 100% */
        letter-spacing: -0.13px;
      }
    }
  }

  .eligible-for-filter-only {
    // to manage inconsistent margins
    .nft-carousel {
      height: 284px;
      margin-top: 0;
    }

    .mint-button {
      margin-top: 56px;
    }

    .journey-else-collections {
      margin-top: 0;
    }
  }

  // raffle done

  .raffle-done {
    width: 100%;

    .raffle-done-titles-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 16px 0;

      .raffle-done-title {
        p {
          color: var(--primary-color);
          font-family: Baloo;
          font-size: 28px;
          font-weight: 400;
          line-height: 34px; /* 121.429% */
          letter-spacing: 0.22px;
        }
      }

      .raffle-done-subtitle {
        color: var(--primary-color);
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px; /* 131.25% */
        letter-spacing: -0.18px;
      }
    }

    .raffle-done-mint-limit {
      color: var(--secondary-color);

      font-family: Inter;
      font-size: 15px;
      font-weight: 500;
      line-height: 15px; /* 100% */
      letter-spacing: -0.13px;
      margin-bottom: 30px;
    }

    .raffle-done-mint-progress {
      // overridden the width here to meet design for raffle ongoing and raffle done UI
      .mint-progress {
        max-width: 676px;
        width: 100%;
      }
    }

    .raffle-done-time-remaining {
      display: flex;
      padding: 24px;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      border-radius: 16px;
      background: var(--quinary-color);

      .raffle-done-time-remaining-label {
        color: var(--primary-color);
        text-align: center;

        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 15px; /* 100% */
        letter-spacing: -0.13px;
      }

      .raffle-done-time-remaining-duration {
        color: var(--primary-color);
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px; /* 100% */
        letter-spacing: -0.33px;
      }
    }

    .raffle-done-outcome {
      color: var(--secondary-color);

      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.32px;
      margin: 24px 0;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
    }

    // cound be moved to separate file with component
    .raffle-done-skeleton-boxes {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 70px;

      .raffle-done-skeleton-boxes-1 {
        width: 210px;
        height: 258px;
        border-radius: 16px;
        background: var(--tertiary-color);
      }

      .raffle-done-skeleton-boxes-2,
      .raffle-done-skeleton-boxes-3 {
        width: 210px;
        height: 258px;
        border-radius: 16px;
        background: var(--quinary-color);
      }
    }

    .raffle-nft-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 80px;
      justify-content: center;

      // When value is 24, it doesn't match the design.
      gap: 23px;

      .raffle-nft-list-item {
        width: 210px;

        .raffle-nft-list-item-image {
          width: 210px;
          height: 210px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 16px;
          }
        }

        .raffle-nft-list-item-label {
          display: flex;
          padding: 16px 24px;
          background: var(--quinary-color);
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;

          span {
            flex: 1 0 0;
            color: var(--primary-color);
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px; /* 100% */
            letter-spacing: -0.18px;
          }
        }
      }
    }

    @media only screen and (max-width: 744px) {
      .raffle-nft-list {
        gap: 16px;
        margin-top: 90px;
      }
    }

    @media only screen and (max-width: 430px) {
      .raffle-done-mint-progress {
        // overridden the width here to meet design for raffle ongoing and raffle done UI
        .mint-progress {
          max-width: 676px;
          width: 100%;
          margin-bottom: 30px;
        }
      }

      .raffle-done-time-remaining {
        .raffle-done-time-remaining-label {
          color: var(--secondary-color);
          text-align: center;

          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 16px; /* 100% */
          letter-spacing: -0.18px;
        }

        .raffle-done-time-remaining-duration {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px; /* 100% */
          letter-spacing: -0.18px;
        }
      }

      .raffle-done-outcome {
        margin: 16px 0;
        padding: 0 16px;
      }

      .raffle-done-skeleton-boxes {
        flex-wrap: wrap;
        gap: 16px;

        .raffle-done-skeleton-boxes-1 {
          width: 191px;
          height: 239px;
        }

        .raffle-done-skeleton-boxes-2,
        .raffle-done-skeleton-boxes-3 {
          width: 191px;
          height: 239px;
        }
      }

      .raffle-nft-list {
        gap: 16px;
        margin-top: 100px;

        .raffle-nft-list-item {
          width: 187px;
          height: 191px;

          .raffle-nft-list-item-image {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              border-radius: 16px;
            }
          }

          .raffle-nft-list-item-label {
            display: flex;
            padding: 16px 24px;
            background: var(--quinary-color);
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;

            span {
              flex: 1 0 0;
              color: var(--primary-color);
              text-align: center;
              font-family: Inter;
              font-size: 16px;
              font-weight: 500;
              line-height: 16px; /* 100% */
              letter-spacing: -0.18px;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 320px) {

      
      .raffle-nft-list {
        gap: 16px;
        margin-top: 110px;

        .raffle-nft-list-item {
          width: 136px;
          height: 136px;
        }
      }

      .raffle-done-mint-progress {
        // overridden the width here to meet design for raffle ongoing and raffle done UI
        .mint-progress {
          max-width: 676px;
          width: 100%;
          margin-bottom: 30px;
        }
      }

      .raffle-done-time-remaining {
        .raffle-done-time-remaining-label {
          color: var(--secondary-color);
          text-align: center;

          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 16px; /* 100% */
          letter-spacing: -0.18px;
        }

        .raffle-done-time-remaining-duration {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px; /* 100% */
          letter-spacing: -0.18px;
        }
      }

      .raffle-done-outcome {
        margin: 16px 0;
        padding: 0 16px;
      }

      .raffle-done-skeleton-boxes {
        flex-wrap: wrap;
        gap: 16px;

        .raffle-done-skeleton-boxes-1 {
          width: 132px;
          height: 184px;
        }

        .raffle-done-skeleton-boxes-2,
        .raffle-done-skeleton-boxes-3 {
          width: 136px;
          height: 184px;
        }
      }
    }
  }

  // timeout auto selection
  .timeout {
    width: 100%;
    max-width: 676px;
    display: flex;
    flex-direction: column;

    .timeout-titles-container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .timeout-title {
        color: var(--primary-color);
        font-family: Baloo;
        font-size: 28px;
        font-weight: 400;
        line-height: 28px; /* 100% */
        letter-spacing: 0.22px;
      }

      .timeout-subtitle {
        color: var(--primary-color);

        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px; /* 131.25% */
        letter-spacing: -0.18px;
      }
    }

    .timeout-nft {
      display: flex;
      gap: 24px;
      margin: 30px 0;

      .timeout-nft-image {
        width: 248px;
        height: 248px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 24px;
        }
      }

      .timeout-nft-info {
        width: 404px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;

        .timeout-nft-info-title {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .timeout-nft-info-heading {
            color: var(--secondary-color);
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px; /* 100% */
            letter-spacing: -0.18px;
          }

          .timeout-nft-info-name {
            color: var(--primary-color);
            font-family: "Baloo 2";
            font-size: 22px;
            font-weight: 600;
            line-height: 22px; /* 100% */
            letter-spacing: 0.22px;
          }
        }

        .timeout-nft-info-traits {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          justify-content: flex-start;

          .timeout-nft-info-single-trait {
            width: 126.66px;
            height: 89px;
            display: flex;
            padding: 16px 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            border-radius: 12px;
            background: var(--quinary-color);

            .timeout-nft-info-single-trait-type {
              color: var(--secondary-color);
              font-family: Inter;
              font-size: 13px;
              font-weight: 500;
              line-height: 13px; /* 100% */
              letter-spacing: -0.04px;
            }

            .timeout-nft-info-single-trait-value {
              color: var(--primary-color);
              font-family: Inter;
              font-size: 15px;
              font-weight: 500;
              line-height: 15px; /* 100% */
              letter-spacing: -0.13px;
            }
          }
        }
      }
    }

    // over-riding the mint-button styles
    .mint-button {
      align-self: center;
    }

    .timeout-switch-and-mint {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  @media only screen and (max-width: 744px) {
  }

  @media only screen and (max-width: 430px) {
    .timeout {
      .timeout-titles-container {
        .timeout-title {
        }

        .timeout-subtitle {
          color: var(--primary-color);

          font-size: 15px;
          font-weight: 400;
          line-height: 20px; /* 133.333% */
          letter-spacing: -0.13px;
        }
      }

      .timeout-nft {
        margin-top: 0;
        flex-direction: column;
        gap: 0;
        align-items: center;

        .timeout-nft-image {
          margin: 30px 0 24px 0;
        }

        .timeout-nft-info {
          width: 100%;
          max-width: 404px;

          .timeout-nft-info-traits {
            flex-wrap: wrap;

            .timeout-nft-info-single-trait {
              width: 126.66px;

              .timeout-nft-info-single-trait-type {
              }

              .timeout-nft-info-single-trait-value {
              }
            }
          }
        }
      }

      .timeout-switch-and-mint {
        .mint-progress {
          margin: 30px 0;
        }
      }
    }
  }

  @media only screen and (max-width: 320px) {
  }
}
