.activate-persona {
  width: 100%;
  max-width: 676px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .activate-persona-titles {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .activate-persona-titles-main {
      color: var(--primary-color);
      font-family: Baloo;
      font-size: 28px;
      font-weight: 400;
      line-height: 28px; /* 100% */
      letter-spacing: 0.22px;
    }

    .activate-persona-titles-sub {
      color: var(--primary-color);
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.18px;
    }
  }

  .activate-persona-tx-hash {
    display: flex;
    align-items: center;
    gap: 8px;

    .activate-persona-tx-hash-left-section {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .activate-persona-tx-hash-label {
        width: 75px;
        color: var(--primary-color);
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.13px;
      }
  
      .activate-persona-tx-hash-value {
        color: var(--cta-color);
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.13px;
      }
    }

    

    .activate-persona-tx-hash-copyicon {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
  }

  .activate-persona-download {
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    flex-wrap: wrap;

    .activate-persona-download-qr {  

      div {
        width: 260px;
        height: 260px;

      }
    }

    .activate-persona-download-store {
      width: 383px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .activate-persona-download-store-cue {
        color: var(--primary-color);
        font-family: Inter;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px; /* 100% */
        letter-spacing: -0.13px;
      }

      .activate-persona-download-store-icons {
        display: flex;
        gap: 12px;
      }

      .activate-persona-download-store-unique {
        color: var(--secondary-color);
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.13px;
      }
    }
  }

  .activate-persona-qr-privacy {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    width: 260px;
    height: 260px;
    border: 1px solid var(--tertiary-color);
    border-radius: 16px;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .activate-persona-qr-privacy-veil {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 24px;
      gap: 12px;
      
      /* 20% Blur */
      backdrop-filter: blur(15px);
    }

    .activate-persona-qr-privacy-disclaimer {
      width: 212px;
      height: 100px;
      color: var(--primary-color);
      font-family: Inter;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.13px;
      text-align: center;

      display: flex;
      flex-direction: column;

      .activate-persona-qr-privacy-disclaimer-title {
        font-weight: 700;
      }
    }

    .activate-persona-qr-privacy-reveal {
      color: var(--cta-color);
      text-align: center;

      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.18px;
      cursor: pointer;
    }
  }

  // .activate-persona-nft-preview-container {
  //   display: flex;
  //   justify-content: center;
  // }


  @media only screen and (max-width: 1440px) {
      
  }

  @media only screen and (max-width: 744px) {
    .activate-persona-download {
      align-items: normal;
      justify-content: center;
      gap: 24px;

      .activate-persona-download-store {

        
      }
    }
  }

  @media only screen and (max-width: 430px) {
    .activate-persona-download {
      align-items: normal;
      justify-content: center;
      gap: 24px;

      .activate-persona-download-store {
        width: 100%;
        max-width: 384px;

        .activate-persona-download-store-cue {
          line-height: 20px; /* 133.333% */
          letter-spacing: -0.13px;
        }

        .activate-persona-download-store-icons {
          justify-content: center;

          svg {
            width: 156px;
            height: 46.141px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 320px) {
    .activate-persona-download {
      align-items: normal;
      justify-content: center;
      gap: 24px;

      .activate-persona-download-qr {
        div {
          width: 196px;
          height: 196px;
        }
      }

      .activate-persona-qr-privacy {
        width: 196px;
        height: 196px;

        img {
          width: 196px;
          height: 196px;
        }

        .activate-persona-qr-privacy-veil {
          padding: 0 24px;

          .activate-persona-qr-privacy-disclaimer {
            width: 148px;
            height: 160px;
            font-size: 15px;
            line-height: 18px; /* 138.462% */
            letter-spacing: -0.04px;

            .activate-persona-qr-privacy-disclaimer-title {
              font-weight: 700;
              line-height: 20px; /* 133.333% */
              letter-spacing: -0.13px;
            }

            .activate-persona-qr-privacy-disclaimer-subtitle {
              line-height: 20px;
              letter-spacing: -0.13px;
            }
          }

          .activate-persona-qr-privacy-reveal {
            font-size: 16px;
            line-height: 21px; /* 131.25% */
            letter-spacing: -0.18px;
          }
        }
      }

      .activate-persona-download-store {

        .activate-persona-download-store-icons {
          justify-content: center;
          
          svg {
            width: 136px;
            height: 40.296px;
          }
        }
      }
    }

    // .activate-persona-nft-preview-container {

    //   .nft-pair-nft-image  {
    //     width: 164px;
    //     height: 164px;

    //     .nft-pair-profile {

    //       .nft-pair-profile-image {
    //         width: 48px;
    //         height: 48px;
    //       }
    //     }
    //   }
    // }
  }
}
