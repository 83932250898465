.tx-pending {
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  // padding: 16px;

  .tx-pending-titles {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .tx-pending-title {
      color: var(--primary-color);
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px; /* 100% */
      letter-spacing: -0.18px;
    }
  
    .tx-pending-subtitle {
      color: var(--secondary-color);
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      line-height: 13px; /* 100% */
      letter-spacing: -0.04px;
    }
  }

  .tx-pending-progress-outer {
    width: 288px;
    height: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    background: var(--primary-R-color);

    .tx-pending-progress-inner {
      height: 100%;
      border-radius: 4px;
      background: var(--cta-color);
    }
  }

}



.tx-reject {
    display: flex;
    align-items: center;
    gap: 16px;
    
  .tx-reject-close-icon {
    width: 36px;
    height: 36px;
  }

  .tx-reject-titles {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .tx-reject-title {
      color: var(--primary-color);
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px; /* 100% */
      letter-spacing: -0.18px;
    }

    .tx-reject-subtitle {
      color: var(--secondary-color);
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      line-height: 13px; /* 100% */
      letter-spacing: -0.04px;
    }
  }
}