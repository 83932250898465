.lp-get-started-container {
  position: relative;
}

.lp-get-started-container-icon {
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: contain;

  &:nth-child(1) {
    background-image: url("../../../assets/svg/bowl-cut-icon.svg");
    top: -80px;
    right: -20px;
    width: 96px;
    height: 120px;
  }
  &:nth-child(2) {
    background-image: url("../../../assets/svg/tv-dance-icon.svg");
    bottom: -160px;
    left: -16px;
    width: 120px;
    height: 215px;
  }

  @media only screen and (min-width: 744px) {
    &:nth-child(1) {
      right: -50px;
      width: 160px;
      height: 300px;
    }
    &:nth-child(2) {
      bottom: -220px;
      left: -10px;
      width: 154px;
      height: 360px;
    }
  }
  @media only screen and (min-width: 1440px) {
    &:nth-child(1) {
      top: 0;
      right: -105px;
      width: 300px;
      height: 360px;
    }
    &:nth-child(2) {
      bottom: -400px;
      left: -50px;
      width: 300px;
      height: 660px;
    }
  }
  @media only screen and (min-width: 1920px) {
    &:nth-child(1) {
      top: 0;
      right: 0;
      width: 300px;
      height: 460px;
    }
    &:nth-child(2) {
      bottom: -400px;
      left: 0;
      width: 300px;
      height: 660px;
    }
  }
}
