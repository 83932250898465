.wrapper {
  width: 100%;
  //   max-width: 1200px;

  display: grid;
  justify-content: space-between;

  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 60px;

  @media (max-width: 768px) {
    row-gap: 32px;
  }

  @media (max-width: 430px) {
    grid-gap: 30px;
    row-gap: 24px;
  }
}

.op-wrapper {
  grid-template-columns: repeat(auto-fit, minmax(300px, 360px));
  justify-content: flex-start;
}

.team-members-card {
  width: 100%;

  display: flex;
  align-items: center;
  gap: 20px;

  .member-pfp {
    width: 100%;
    max-width: 100px;

    border-radius: 12px;

    @media (max-width: 768px) {
      max-width: 80px;
    }

    @media (max-width: 430px) {
      max-width: 66px;
    }
  }

  .member-detail-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .member-name {
      color: #262626;
      font-family: "Baloo 2";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.93px;

      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 22px;
      }

      @media (max-width: 430px) {
        font-size: 20px;
        line-height: 20px;
      }
    }

    .member-position {
      color: #6b6b6b;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px; /* 133.333% */
      letter-spacing: -0.13px;

      @media (max-width: 430px) {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}
