.nft-pair-nft-image {
  width: 208px;
  height: 208px;
  position: relative;
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background: #FAD021;
  
  img {
    width: 100%;
    height: 100%;
    // border-radius: 500px;
  }

  .nft-pair-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    position: absolute;
    bottom: -75px;
    

    .nft-pair-profile-image {
      width: 64px;
      height: 64px;
      border-radius: 10px;
      border: 2px solid #FFF;

      img {
       width: 100%;
       height: 100%;
       border-radius: 10px;
      }
    }

    .nft-pair-profile-name {
      color: var(--primary-color);
      font-family: "Baloo 2";
      font-size: 18px;
      font-weight: 600;
      line-height: 18px; /* 100% */
      letter-spacing: 0.22px;
      text-align: center;
    }
  }


  @media only screen and (max-width: 320px) {
    width: 164px;
    height: 164px;


    .nft-pair-profile {
      gap: 12px;
      bottom: -60px;
      
  
      .nft-pair-profile-image {
        width: 48px;
        height: 48px;
        border-radius: 10px;
        border: 2px solid #FFF;
  
      }
  
      .nft-pair-profile-name {
        color: var(--primary-color);
        font-family: "Baloo 2";
        font-size: 18px;
        font-weight: 600;
        line-height: 18px; /* 100% */
        letter-spacing: 0.22px;
        text-align: center;
      }
    }
  }
}