@use "theme/themes" as *;
@use "theme/utils" as *;

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// button {
//   all: unset;
// }

// --------------- about font family ------------------- //

// Baloo and Baloo 2 font family has been imported in index.html

// ---------------------------------------------------- //

//  ------------ custom styles begins here ------------ //

// pasted from https://rsms.me/inter/
:root {
  font-family: "Inter", sans-serif;
  font-feature-settings: "liga" 1, "calt" 1; /* fix for Chrome */
  //   --border-color: #cccccc;
  //   --background-color: #f1f1f1;
  --transition: all 0.2s ease;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

// ---------------- global styles -------------------- //

* {
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: transparent;
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-tap-highlight-color: transparent;
  -ms-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: transparent;
  tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  scroll-behavior: smooth;
}

/*
  we don't need css properties on html element.
  pasted both the properties on body selector below.
  do not apply style to html element as it causes scrollbar issues when modal is open.
*/

// html,
// body {
//   max-width: 100%;
//   overflow-x: hidden;
// }

body {
  // these 2 properties were earlier applied on html element as well which is not needed
  max-width: 100%;
  overflow-x: hidden;

  background-color: var(--bg-color);
  color: var(--primary-color);
  overscroll-behavior: none;
  min-height: 100vh;

  // TODO: Check if this is needed.
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .main {
    min-height: 100vh;
  }
}

// hides body tag's scroll bar when ReactModal is open.
.ReactModal__Body--open {
  // overflow-y: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  // should be same as closeTimeoutMS prop in Modal.js
  transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--after-open {
  @media only screen and (max-width: 744px) {
    max-height: 95%;
  }

  @media only screen and (max-width: 430px) {
    width: 90%;
    max-height: 95%;
  }

  @media only screen and (max-width: 320px) {
    width: 90%;
    max-height: 95%;
  }
}

.text {
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.13px;

  @media only screen and (min-width: 744px) {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
  }

  &-bold {
    font-weight: 600;
  }

  &-primary {
    color: var(--primary-color);
  }

  &-secondary {
    color: var(--secondary-color);
  }

  &-green {
    color: #93a523;
  }

  &-purple {
    color: #a017d9;
  }

  &-orange {
    color: #ff761c;
  }

  &-blue {
    color: #1e38c0;
  }

  &-white {
    color: #fff;
  }

  &-red {
    color: #eb004e;
  }

  &-align-center {
    text-align: center;
  }
}

.noselect {
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// w3m-modal {
//   border-width: 10px;

//   w3m-header {
//     border-width: 10px !important;
//     width: 100px !important;
//     background-color: red !important;
//   }
// }
