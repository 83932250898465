.offer-help {
  color: var(--cta-color);
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px; /* 100% */
  letter-spacing: -0.13px;

  cursor: pointer;
  text-align: center;
}