.news-card-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 24px;
  margin-bottom: 40px;

  @media only screen and (min-width: 744px) {
    gap: 24px;
    margin-bottom: 60px;
  }
  @media only screen and (min-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 36px;
    margin-bottom: 80px;
  }
}

.news-card {
  flex: 1 1 0;
  min-width: 140px;
  min-height: 140px;

  &-header {
    min-width: auto;
    min-height: inherit;
    margin-bottom: 24px;

    img {
      min-width: inherit;
      min-height: inherit;
      height: 100%;
      width: 100%;
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &-timestamp {
    font-family: "Inter";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.04px;
    align-self: stretch;
    color: var(--primary-80-color);

    a {
      font-size: inherit;
    }
  }
  &-title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.13px;
    color: var(--primary-color);

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
  }

  @media only screen and (min-width: 744px) {
    min-width: 300px;
    min-height: 260px;
    flex-basis: 40%;

    &-timestamp {
      font-size: 16px;
      line-height: 16px;
    }
    &-title {
      font-size: 20px;
      line-height: 25px;
      letter-spacing: -0.33px;
    }
  }
  @media only screen and (min-width: 1440px) {
    min-width: auto;
    flex-basis: 20%;
  }
}

.news-section {
  &-footer {
    display: flex;
    justify-content: center;

    * {
      display: inline-flex;
      gap: 4px;
    }
    a {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
