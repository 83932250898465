.lp-xtm-main {
  // margin-top: 92px;
  // background: rgb(255, 255, 255);
  // background: linear-gradient(180deg, rgb(210, 214, 253) 0%, rgba(255, 255, 255, 1) 100%);
}

.lp-xtm-hero {
  width: 100%;
  height: 100vh;

  padding-top: 92px;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  @media (max-width: 430px) {
    height: 700px;
  }

  .lp-xtm-hero-maincharacter {
    width: 700px;
    height: 700px;
    object-fit: contain;

    position: absolute;
    left: 55%;

    @media (max-width: 1440px) {
      width: 600px;
      height: 600px;
      bottom: 0;
    }

    @media (max-width: 960px) {
      width: 500px;
      height: 500px;
      bottom: 40px;
      left: 50%;
    }

    @media (max-width: 768px) {
      width: 400px;
      height: 400px;
      left: 40%;
      bottom: 50px;
    }

    @media (max-width: 430px) {
      width: 260px;
      height: 260px;
      left: 38%;
      bottom: 40px;
    }
  }

  .lp-xtm-hero-secondcharacter {
    width: 240px;
    height: 240px;
    object-fit: contain;

    position: absolute;
    top: 100px;
    left: 45%;

    @media (max-width: 1440px) {
      width: 240px;
      height: 240px;
      left: 55%;
    }

    @media (max-width: 960px) {
      left: 15%;
    }

    @media (max-width: 768px) {
      left: 5%;
    }

    @media (max-width: 430px) {
      display: none;
    }
  }

  // .lp-xtm-hero-cloudone {
  //   width: 500px;
  //   height: 500px;
  //   object-fit: contain;

  //   position: absolute;
  //   left: 80%;
  //   top: -100px;

  //   @media (max-width: 1440px) {
  //   }

  //   @media (max-width: 960px) {
  //   }

  //   @media (max-width: 768px) {
  //   }

  //   @media (max-width: 430px) {
  //     width: 260px;
  //     height: 260px;
  //     top: 40px;
  //     left: 60%;
  //   }
  // }

  // .lp-xtm-hero-cloudtwo {
  //   width: 400px;
  //   height: 400px;
  //   object-fit: contain;
  //   z-index: 0;
  //   position: absolute;
  //   left: 85%;
  //   bottom: 0;

  //   @media (max-width: 1440px) {
  //   }

  //   @media (max-width: 960px) {
  //   }

  //   @media (max-width: 768px) {
  //   }

  //   @media (max-width: 430px) {
  //   }
  // }

  // .lp-xtm-hero-cloudthree {
  //   width: 350px;
  //   height: 350px;
  //   object-fit: contain;

  //   position: absolute;
  //   left: 0;
  //   bottom: 0;

  //   @media (max-width: 1440px) {
  //   }

  //   @media (max-width: 960px) {
  //   }

  //   @media (max-width: 768px) {
  //   }

  //   @media (max-width: 430px) {
  //     width: 140px;
  //     height: 140px;
  //     left: -20px;
  //   }
  // }

  .lp-xtm-hero-coinone {
    width: 160px;
    height: 160px;
    object-fit: contain;

    position: absolute;

    bottom: 120px;
    right: 55%;

    @media (max-width: 1440px) {
    }

    @media (max-width: 960px) {
      width: 140px;
      height: 140px;
      right: 60%;
      bottom: 100px;
    }

    @media (max-width: 768px) {
      width: 120px;
      height: 120px;
    }

    @media (max-width: 430px) {
      width: 80px;
      height: 80px;
      bottom: 150px;
      right: 65%;
    }
  }

  .lp-xtm-hero-cointwo {
    width: 120px;
    height: 120px;
    object-fit: contain;

    position: absolute;

    left: 20%;
    top: 150px;

    @media (max-width: 1440px) {
    }

    @media (max-width: 960px) {
      left: 70%;
    }

    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
      left: 65%;
    }

    @media (max-width: 430px) {
      display: none;
    }
  }

  .lp-xtm-hero-content {
    width: calc(100% - 72px);
    max-width: 1200px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    row-gap: 24px;

    z-index: 1;

    @media (max-width: 768px) {
      row-gap: 16px;

      padding-bottom: 100px;
    }

    @media (max-width: 430px) {
      width: calc(100% - 32px);
      align-items: center;
      gap: 12px;

      padding-bottom: 240px;
    }

    .lp-xtm-hero-text {
      color: #262626;
      text-align: center;
      font-family: "Baloo 2";
      font-size: 100px;
      font-style: normal;
      font-weight: 800;
      line-height: 60px;
      letter-spacing: -0.93px;

      text-align: left;

      &-bold {
        font-weight: 800;
        color: #ac3edb;
      }

      @media (max-width: 960px) {
        font-size: 80px;
      }

      @media (max-width: 768px) {
        font-size: 58px;
        white-space: nowrap;
      }

      @media (max-width: 430px) {
        text-align: left;
        font-size: 48px;
        white-space: nowrap;
        line-height: 48px;
      }

      @media (max-width: 360px) {
        font-size: 42px;
        white-space: nowrap;
      }
    }

    #lp-xtm-hero-text-description {
      color: #262626;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.18px;

      width: 100%;
      max-width: 600px;

      @media (max-width: 960px) {
        max-width: 500px;
      }

      @media (max-width: 768px) {
        font-size: 17px;
        line-height: 23px;
        max-width: 450px;
      }

      @media (max-width: 430px) {
        text-align: center;

        max-width: 320px;
      }
    }

    .lp-xtm-hero-button {
      display: flex;
      width: 240px;
      height: 54px;
      padding: 0px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      border-radius: 16px;

      color: #ffffff;
      background-color: #262626;

      text-align: center;
      font-family: "Baloo 2";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 100% */
      letter-spacing: -0.46px;

      margin-top: 16px;

      @media (max-width: 430px) {
        width: 160px;

        height: 46px;

        font-size: 17px;
        font-weight: 500;
      }
    }

    a {
      width: min-content;
      text-decoration: none;
    }
  }
}

// Body-------------------------------------------------------------------------------------------------------------------------------------------------------

.lp-xtm-body {
  width: 100%;

  .lp-xtm-body-wrapper {
    width: 100%;
    // max-width: 1200px;

    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: visible;

    .lp-xtm-hero-sub {
      width: calc(100% - 72px);
      max-width: 800px;

      color: #262626;
      text-align: center;
      font-family: Baloo;
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: -0.93px;

      margin-top: 120px;

      @media (max-width: 768px) {
        margin-top: 180px;
      }

      @media (max-width: 430px) {
        margin-top: 100px;

        font-size: 30px;
        line-height: 38px;
      }

      span {
        color: #4966e7;
      }
    }

    .lp-xtm-whyhow-container {
      width: calc(100% - 72px);
      max-width: 1200px;
      height: auto;
      min-height: 500px;

      display: flex;
      justify-content: space-between;
      gap: 46px;

      margin-top: 80px;

      overflow: visible;

      @media (max-width: 768px) {
        flex-direction: column;

        margin-top: 120px;
      }

      @media (max-width: 430px) {
        margin-top: 60px;
      }

      .lp-xtm-whyhow-wrapper {
        width: 100%;
        max-width: 560px;

        display: flex;
        flex-direction: column;
        gap: 12px;

        background-color: #ffffff;

        padding: 24px 36px;

        border-radius: 36px;

        @media (max-width: 430px) {
          border-radius: 26px;

          padding: 20px 24px;
        }

        &.first {
          transform: rotate(-6deg);
          margin-bottom: auto;

          background-color: #ac3edb;
          border: 3px dashed #422a83;

          #lp-xtm-whyhow-title {
            color: #ffffff;
          }

          #lp-xtm-whyhow-description {
            color: #ffffff;
          }
        }

        &.second {
          transform: rotate(6deg);

          border: 3px dashed #83372a;
          background-color: #f4485d;

          margin-top: auto;
          margin-left: auto;

          #lp-xtm-whyhow-title {
            color: #ffffff;
          }

          #lp-xtm-whyhow-description {
            color: #ffffff;
          }
        }

        .lp-xtm-whyhow-icon {
          width: 80px;
          height: 80px;

          @media (max-width: 430px) {
            width: 50px;
            height: 50px;
          }
        }

        #lp-xtm-whyhow-title {
          font-family: "Baloo 2";
          font-size: 36px;
          font-style: normal;
          font-weight: 800;
          line-height: 36px;
          letter-spacing: -0.93px;

          @media (max-width: 430px) {
            font-size: 30px;
            line-height: 34px;
          }
        }

        #lp-xtm-whyhow-description {
          color: #262626;
          font-family: Inter;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: -0.18px;

          @media (max-width: 430px) {
            font-size: 15px;
            line-height: 23px;
          }
        }
      }
    }

    .lp-xtm-usecase-container {
      width: 100%;
      max-width: calc(1200px - 72px);
      height: max-content;

      display: flex;

      gap: 36px;

      margin: 40px 0 0;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: 1024px) {
        max-width: calc(1200px - 72px);
      }

      @media (max-width: 768px) {
        overflow: auto;
        padding: 0 32px 24px;
      }

      @media (max-width: 430px) {
        padding-left: 16px;
        padding-right: 16px;
        max-width: 100%;
        gap: 24px;
      }

      .lp-xtm-usecase-container-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;

        width: 100%;
        height: 100%;

        .lp-xtm-usecase-img {
          width: 360px;
          height: 360px;

          @media (max-width: 768px) {
          }

          @media (max-width: 430px) {
            width: 260px;
            height: 260px;
          }
        }

        .first {
          border: 3px solid #a523d1;

          #lp-xtm-usecase-title {
            color: #a523d1;
          }
        }

        .second {
          border: 3px solid #b85c9a;

          #lp-xtm-usecase-title {
            color: #b85c9a;
          }
        }

        .third {
          border: 3px solid #cb9364;
          grid-row: span 2;
          grid-column: span 3;

          #lp-xtm-usecase-title {
            color: #cb9364;
          }
        }

        .lp-xtm-usecase-description-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 24px;

          width: 100%;
          height: min-content;

          border-radius: 26px;
          padding: 32px 32px 24px;
          flex: 1;

          background-color: #ffffff;

          @media (max-width: 430px) {
            width: 100%;

            font-size: 15px;
            line-height: 23px;

            padding: 24px;
          }

          .lp-xtm-usecase-title-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;

            width: 100%;

            @media (max-width: 430px) {
              flex-direction: row;
            }

            .lp-xtm-usecase-icon {
              width: 100%;
              max-width: 60px;

              @media (max-width: 430px) {
                width: 100%;
                max-width: 50px;
              }
            }

            #lp-xtm-usecase-title {
              text-align: center;
              font-family: Baloo;
              font-size: 32px;
              font-style: normal;
              font-weight: 400;
              line-height: 34px;
              letter-spacing: -0.93px;

              @media (max-width: 430px) {
                font-size: 28px;
                line-height: 28px;

                text-align: left;
              }
            }
          }

          p {
            color: #262626;

            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: -0.18px;
          }
        }
      }
    }

    .lp-xtm-oxtm-container {
      width: calc(100% - 72px);
      max-width: 1200px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 24px;

      margin-top: 120px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      @media (max-width: 430px) {
        width: 100%;
        max-width: calc(100% - 32px);

        margin-top: 80px;

        flex-direction: column-reverse;
      }

      .lp-xtm-oxtm-img {
        height: 100%;
        width: 100%;
        max-width: 400px;
        max-height: 400px;

        @media (max-width: 430px) {
          width: 100%;
          max-width: 300px;
          height: 100%;
          max-height: 300px;

          margin: auto;
        }
      }

      .lp-xtm-oxtm-desc-wrapper {
        width: 100%;
        max-width: 680px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .lp-xtm-oxtm-title {
          color: #262626;
          font-family: Baloo;
          font-size: 58px;
          font-style: normal;
          font-weight: 400;
          line-height: 58px;
          letter-spacing: -0.93px;
          width: 100%;

          margin-top: auto;

          @media (max-width: 768px) {
            font-size: 48px;
            white-space: nowrap;
          }

          @media (max-width: 430px) {
            font-size: 36px;
          }

          #bold {
            color: #ac3edb;
            font-family: Baloo;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.93px;
          }
        }

        p {
          color: #262626;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: -0.18px;

          @media (max-width: 430px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }

    .lp-xtm-get-container {
      width: calc(100% - 72px);
      max-width: 1200px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 40px;

      margin-top: 160px;

      @media (max-width: 430px) {
        width: 100%;
        max-width: calc(100% - 32px);

        margin-top: 120px;
      }

      .lp-xtm-get-title {
        color: #262626;
        font-family: Baloo;
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px; /* 100% */
        letter-spacing: -0.93px;

        span {
          color: #ac3edb;
          font-family: Baloo;
          font-size: 38px;
          font-style: normal;
          font-weight: 400;
          line-height: 38px;
          letter-spacing: -0.93px;
        }
      }

      .lp-xtm-get-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        grid-template-rows: repeat(auto-fit, 1fr);
        gap: 36px;

        width: 100%;

        @media (max-width: 430px) {
          gap: 16px;
        }

        a {
          text-decoration: none;
          -webkit-text-decoration-line: none;
        }

        .unavailable {
          opacity: 0.4;
        }

        .lp-xtm-get-panel {
          position: relative;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 16px;

          padding: 36px 24px;

          border-radius: 26px;
          outline: #c1caee solid 1px;

          background-color: #ffffff;

          @media (max-width: 430px) {
            flex-direction: row;
            justify-content: flex-start;

            padding: 24px 24px;

            border-radius: 16px;
          }

          .lp-xtm-get-coming-soon {
            position: absolute;
            top: -12px;
            right: -12px;

            color: #262626;
            font-family: "Baloo 2";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: -0.1px;

            color: #ffffff;
            background-color: #4966e7;

            padding: 12px 16px;

            border-radius: 500px;

            @media (max-width: 430px) {
              font-size: 17px;
              padding: 8px 12px;
            }
          }

          .lp-xtm-get-img {
            width: 100px;

            @media (max-width: 430px) {
              width: 70px;
            }
          }

          .desc-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            @media (max-width: 430px) {
              align-items: flex-start;

              gap: 2px;
            }

            #panel-title {
              color: #262626;
              font-family: Baloo;
              font-size: 30px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: -0.93px;

              @media (max-width: 430px) {
                font-size: 24px;
              }
            }

            #panel-text {
              font-size: 15px;
              line-height: 20px;
              font-weight: 500;
              color: #7c7c7c;
            }
          }
        }
      }
    }
  }
}

.lp-xtm-sub-bg {
  width: 100%;
  height: 100vh;

  position: fixed;
  object-fit: cover;

  overflow: hidden;

  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // bottom: 0;

  left: 0;
  right: 0;
  z-index: -1;

  transition: all 0ms ease-in-out;

  // margin-top: 180px;
}

.lp-xtm-usecase-container .swiper {
  padding-bottom: 40px;
  touch-action: pan-y;
}
