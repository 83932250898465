.lp-letter-main {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 120px 0 0;

  gap: 120px;

  position: relative;

  @media (max-width: 430px) {
    padding: 60px 0 0;

    gap: 60px;
  }

  .lp-letter-body {
    max-width: 900px;

    display: flex;
    flex-direction: column;
    gap: 40px;

    margin: 0 36px;

    @media (max-width: 430px) {
      margin: 0 16px;

      gap: 30px;
    }

    .lp-letter-title {
      color: #262626;
      text-align: center;
      font-family: Sriracha;
      font-size: 56px;
      font-style: normal;
      font-weight: 600;
      line-height: 58px;
      letter-spacing: 0;

      margin-bottom: 60px;

      @media (max-width: 430px) {
        font-size: 40px;
        line-height: 44px;

        margin-bottom: 30px;
      }

      #the-founder {
        color: #ac3edb;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 600px) {
          display: inline-block;
        }

        @media (max-width: 500px) {
          display: inline;
        }
      }
    }

    #lp-letter-starter {
      color: #525252;
      font-family: Sriracha;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */

      @media (max-width: 430px) {
        font-size: 17px;
      }
    }

    #lp-letter-text {
      color: #525252;
      font-family: Sriracha;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;

      @media (max-width: 430px) {
        font-size: 17px;
        line-height: 25px;
      }
    }
  }

  .lp-letter-closing {
    width: calc(100% - 72px);
    max-width: 900px;

    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 430px) {
      width: calc(100% - 32px);

      gap: 8px;
    }

    #lp-letter-closing-name {
      color: #262626;
      font-family: Sriracha;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;

      @media (max-width: 430px) {
        font-size: 18px;
      }
    }

    #lp-letter-closing-sincere {
      color: #6b6b6b;
      font-family: Sriracha;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      @media (max-width: 430px) {
        font-size: 18px;
      }
    }
  }

  .lp-letter-bg-1 {
    width: 90%;

    background-position: center;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    z-index: -1;

    @media (max-width: 430px) {
      transform: rotate(-300deg);
      width: 100%;

      right: 0;
      top: 0;
    }
  }

  .lp-letter-bg-2 {
    width: 100%;

    background-position: center;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 860px;

    z-index: -1;

    @media (max-width: 430px) {
      transform: rotate(-320deg);
      width: 100%;

      right: 0;
      bottom: 280px;
    }
  }

  .lp-letter-bottom-art {
    width: 100%;

    display: flex;

    background-position: bottom;

    margin-top: -180px;

    @media (max-width: 430px) {
      width: 160%;

      margin-top: -100px;
    }
  }
}

.marquee-container {
  width: 100%;

  display: flex;
  gap: 12px;

  @media only screen and (min-width: 744px) {
    gap: 16px;
  }
  @media only screen and (min-width: 1440px) {
    gap: 28px;
  }
  @media only screen and (min-width: 1920px) {
    /*  same as above */
  }
}

.letter-marquee-img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
  margin-right: 90px;

  @media (max-width: 430px) {
    height: 220px;

    margin-right: 16px;
  }

  &-1 {
    background-image: url("../../assets/letter-marquee-1.png");
    width: 800px;

    @media (max-width: 430px) {
      width: 260px;
    }
  }
  &-2 {
    background-image: url("../../assets/letter-marquee-2.png");
    width: 420px;

    @media (max-width: 430px) {
      width: 160px;
    }
  }
  &-3 {
    background-image: url("../../assets/letter-marquee-3.png");
    width: 800px;
    @media (max-width: 430px) {
      width: 260px;
    }
  }
  &-4 {
    background-image: url("../../assets/letter-marquee-4.png");
    width: 420px;

    @media (max-width: 430px) {
      width: 160px;
    }
  }
  &-5 {
    background-image: url("../../assets/letter-marquee-5.png");
    width: 800px;

    @media (max-width: 430px) {
      width: 260px;
    }
  }
  &-6 {
    background-image: url("../../assets/letter-marquee-6.png");
    width: 420px;

    @media (max-width: 430px) {
      width: 160px;
    }
  }

  //   @media only screen and (min-width: 744px) {
  //     margin-right: 16px;
  //   }

  //   @media only screen and (min-width: 1440px) {
  //     width: 600px;
  //     height: 600px;
  //     margin-right: 28px;
  //   }
}
