.lp-nft-container {
  .lp-section {
    margin-bottom: 80px;

    @media only screen and (min-width: 744px) {
      margin-bottom: 80px;
    }
    @media only screen and (min-width: 1440px) {
      margin-bottom: 120px;
    }
  }
}

// HERO section

.lp-nft-hero {
  width: 100%;
  margin-top: 92px;

  video {
    width: calc(100% - 40px);
    display: block;
    max-width: 1360px;

    margin: 0 auto 0;

    object-fit: cover;

    pointer-events: none;
    background-color: #ffffff;
    filter: drop-shadow(1px 1px 1px #ffffff);

    @media (max-width: 768px) {
      width: 100%;
    }

    @media (max-width: 430px) {
      width: 100%;

      margin-top: 16px;
    }
  }
}

// CHUBBY CUB info section
.lp-nft-info-container {
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 36px;
  padding-top: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
    padding-top: 16px;
  }

  // @media only screen and (min-width: 1440px) {
  //   flex-direction: row;
  //   justify-content: space-between;
  //   padding-top: 36px;
  // }
}

.lp-nft-info-list {
  width: 100%;
  display: flex;
  gap: 36px;
  // flex-wrap: wrap;
  // justify-content: center;
  // gap: 30px;
  // max-width: 300px;

  // @media only screen and (min-width: 744px) {
  //   gap: 48px;
  //   max-width: 100%;
  // }

  @media (max-width: 768px) {
    justify-content: space-evenly;
    gap: 8px;
  }

  &-item {
    display: flex;
    align-items: center;
    gap: 12px;

    .lp-nft-info-list-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 980px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      @media (max-width: 430px) {
        P {
          font-size: 13px;
        }
      }
    }

    @media (max-width: 980px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    //   display: flex;
    //   flex: 1 0 0;
    //   gap: 12px;

    //   &:nth-child(1),
    //   &:nth-child(3) {
    //     justify-content: flex-end;
    //   }
    //   &:last-child {
    //     justify-content: center;
    //   }

    //   @media only screen and (min-width: 744px) {
    //     text-align: center;
    //     flex-direction: column;
    //     gap: 8px;
    //     align-items: center;
    //     justify-content: center;
    //   }

    //   @media only screen and (min-width: 1440px) {
    //     text-align: left;
    //     flex-direction: row;
    //     gap: 12px;
    //   }
  }
}

.lp-nft-info-cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media only screen and (min-width: 744px) {
    flex-direction: row;
  }
}

.lp-nft-info-cta-btn-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;

  width: max-content;

  padding: 12px 24px 12px 16px;

  border-radius: 26px;

  background-color: #fff01f;

  .blast-icon {
    width: 36px;
  }

  @media (max-width: 430px) {
    padding: 8px 16px 8px 12px;
  }

  .lp-nft-info-chain-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      color: #262626;
    }

    @media (max-width: 430px) {
      flex-direction: row;
      gap: 8px;

      font-size: 15px;
    }
  }
}

// INTRO section
.lp-nft-about {
  &-container {
    padding-top: 30px;

    @media only screen and (min-width: 1440px) {
      padding-top: 36px;
    }
  }

  &-paragraphs {
    display: flex;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 16px;
    }

    p {
      background-color: #fdfdfd;
      color: #262626;
      padding: 18px 24px;

      border: #dfdfdf solid 1px;
      border-radius: 26px;

      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: -0.18px;

      width: calc(100% - 72px);

      @media (max-width: 768px) {
        width: 100%;

        font-size: 15px;
        line-height: 21px;
      }
    }

    // @media only screen and (min-width: 1440px) {
    //   flex-direction: row;

    //   p {
    //     flex: 1;
    //   }
    // }
  }

  &-progress-bar {
    margin-top: 36px;
  }
}

.progress-bar {
  &-track {
    position: relative;
    background-color: #d9d9d9;
    height: 6px;
    border-radius: 16px;
  }
  &-status {
    background-color: #ac3edb;
    height: inherit;
    border-radius: inherit;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  &-label {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }
}

.lp-nft-invite {
  &-section {
    padding-bottom: 0;

    .section-footer {
      align-items: flex-start;

      @media only screen and (min-width: 1440px) {
        flex-direction: row;
        align-items: flex-start;
        gap: 56px;
        p {
          flex: 1 1 0;
        }
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    padding: 24px;
    border-radius: 36px;
    background-color: #f4f4f4;

    a {
      text-decoration: none;
    }

    @media only screen and (min-width: 744px) {
      padding: 36px;
    }
    @media only screen and (min-width: 1440px) {
      flex-direction: row;
      padding: 24px 36px;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;

    &-item {
      position: relative;

      img {
        border-radius: 500px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        width: 60px;
        height: 60px;
      }
      svg {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30px;
        height: 30px;
      }
    }

    @media only screen and (min-width: 744px) {
      gap: 36px;

      img {
        width: 80px;
        height: 80px;
      }

      &-item:last-child {
        align-items: flex-start;
      }
    }
    @media only screen and (min-width: 1440px) {
      img {
        width: 100px;
        height: 100px;
      }
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
  &-cta {
    text-align: center;
  }
}

.lp-nft-ownership-perks {
  &-section {
    padding-bottom: 0;
  }
}
