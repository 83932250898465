.lp-faq-container {
  .lp-faq-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    > * {
      flex: 1 1 0px;
    }

    @media only screen and (min-width: 1440px) {
      flex-direction: row;
    }
  }

  .lp-section-header {
    margin-bottom: 24px;
  }
  .lp-section-footer {
    margin-top: 40px;
  }

  @media only screen and (min-width: 744px) {
    .lp-section-header {
      margin-bottom: 36px;
    }
    .lp-section-footer {
      margin-top: 60px;
    }
  }
  @media only screen and (min-width: 1440px) {
    .lp-section-header {
      margin-bottom: 0;
    }
  }
}
