.sign {
  width: 100%;
  max-width: 446px;
  height: 186px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  border-radius: 26px;
  background: #FFF;

 .sign-heading {
  color: var(--primary-color);
  text-align: center;

  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px; /* 100% */
  letter-spacing: -0.4px;
 }

 p {
  color: var(--primary-color);
  text-align: center;

  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.13px;
  margin-top: 16px;
  margin-bottom: 24px;
 }

 .sign-button {
  display: flex;
  height: 56px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 20px;
  background: #eaeaea;

  span {
    color: var(--tertiary-color);
    text-align: center;
    font-family: "Baloo 2";
    font-size: 18px;
    font-weight: 600;
    line-height: 18px; /* 100% */
    letter-spacing: -0.46px;
  }
 }

 @media only screen and (max-width: 430px) {
  max-width: 398px;
  padding: 16px;
 }

 @media only screen and (max-width: 320px) {
  max-width: 288px;
  padding: 16px;

 }
}