.lp-your-nft-container {
  margin-top: 100vh;
  background-image: url("../../../assets/nft-bg.png");
  background-repeat: no-repeat;
  background-position-x: center;
  position: relative;

  overflow: hidden;

  &-bg-icon {
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: contain;

    &:nth-child(1) {
      bottom: 47%;
      left: 0;
      width: 60px;
      height: 60px;
      background-image: url("../../../assets/svg/nft-bg-icon-1.svg");
    }
    &:nth-child(2) {
      top: 15%;
      right: 0;
      width: 100px;
      height: 100px;
      background-image: url("../../../assets/svg/nft-bg-icon-2.svg");
    }
    &:nth-child(3) {
      bottom: 0%;
      right: 0;
      width: 208px;
      height: 204px;
      background-image: url("../../../assets/svg/nft-bg-icon-3-mobile.svg");
      background-position: bottom right;
    }

    @media only screen and (min-width: 1440px) {
      &:nth-child(1) {
        bottom: 200px;
        left: 40px;
        width: 140px;
        height: 140px;
        background-image: url("../../../assets/svg/nft-bg-icon-1.svg");
      }
      &:nth-child(2) {
        top: 15%;
        right: -20px;
        width: 200px;
        height: 200px;
        background-image: url("../../../assets/svg/nft-bg-icon-2.svg");
      }
      &:nth-child(3) {
        right: 0;
        bottom: 0;
        width: 400px;
        height: 400px;
        background-image: url("../../../assets/svg/nft-bg-icon-3.svg");
      }
    }
    @media only screen and (min-width: 1920px) {
      &:nth-child(1) {
        top: 450px;
        left: 70px;
        width: 160px;
        height: 200px;
      }
      &:nth-child(2) {
        top: 100px;
        right: 0;
        width: 200px;
        height: 200px;
      }
      &:nth-child(3) {
        right: 0;
        width: 480px;
        height: 380px;
      }
    }
  }

  .lp-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    width: 100%;

    @media (max-width: 620px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    video {
      width: auto;
      height: 800px;
      border-radius: 36px;

      border: #262626 solid 2px;
      background-color: #262626;

      @media (max-width: 1024px) {
        height: 650px;
      }

      @media (max-width: 768px) {
        height: 520px;
        border-radius: 26px;
      }
    }
  }

  .lp-section-header h1 {
    font-family: Baloo;
    font-size: 120px;
    font-weight: 400;
    line-height: 100px;
    letter-spacing: -2px;
    text-transform: uppercase;

    width: 100%;

    text-align: left;

    overflow: visible;

    @media (max-width: 1280px) {
      font-size: 100px;
      line-height: 80%;
    }

    @media (max-width: 1180px) {
      font-size: 90px;
      line-height: 80%;
    }

    @media (max-width: 1024px) {
      font-size: 80px;
      line-height: 80%;
    }

    @media (max-width: 768px) {
      font-size: 58px;
      line-height: 90%;
    }

    @media (max-width: 360px) {
      font-size: 50px;
    }
  }

  .lp-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .carousel {
      margin-top: 80px;
    }
  }

  .lp-section-header {
    // margin-bottom: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;

    @media (max-width: 768px) {
      gap: 16px;
    }

    @media (max-width: 430px) {
      gap: 12px;
    }
  }

  .lp-section-header p {
    width: fit-content;

    font-size: 24px;
    font-weight: 500;
    line-height: 130%;

    color: #ffffff;
    background-color: #262626;
    padding: 16px 24px;
    border-radius: 16px;

    @media (max-width: 1024px) {
      font-size: 18px;
      padding: 12px 16px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 140%;
    }
  }

  .text-nft {
    background: #b400ff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-story {
    background: #ff3a52;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    padding-right: 8px;
  }

  .carousel-slide-title {
    font-family: "Baloo 2";
    font-size: 34px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.93px;

    @media only screen and (min-width: 744px) {
      font-size: 32px;
      line-height: 38.4px; /* 120% */
    }
    @media only screen and (min-width: 1440px) {
      font-size: 38px;
      line-height: 38px; /* 100% */
    }

    .text-bold {
      font-family: "Baloo";
      font-size: 34px;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: -0.93px;

      @media only screen and (min-width: 744px) {
        font-size: 32px;
        line-height: 38.4px;
      }
      @media only screen and (min-width: 1440px) {
        font-size: 38px;
        line-height: 38px;
      }
    }
  }

  .carousel-slide-description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    max-width: 560px;
    margin-bottom: 24px;
  }
}

.lp-nft-story-context {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  position: relative;

  width: 100%;
  max-width: 1200px;
  height: auto;

  margin-top: -80px;

  @media (max-width: 1024px) {
    margin-top: -40px;
  }

  @media (max-width: 960px) {
    margin-top: 60px;
  }

  @media (max-width: 768px) {
    margin-top: 80px;
  }

  @media (max-width: 430px) {
    margin-top: 60px;
  }

  .story-img {
    width: 500px;
    height: 500px;

    @media (max-width: 1024px) {
      width: 450px;
      height: 450px;
    }

    @media (max-width: 960px) {
      width: 300px;
      height: 300px;
    }

    @media (max-width: 768px) {
      width: 250px;
      height: 250px;

      position: absolute;
      z-index: 4;
      top: 0;
      // bottom: -50px;
      bottom: 0;
      left: 0;

      // right: -120px;
      right: 0;

      transform: translate(200%, 0);
    }

    @media (max-width: 600px) {
      transform: translate(160%, 0);
    }

    @media (max-width: 550px) {
      transform: translate(140%, 0);
    }

    @media (max-width: 500px) {
      transform: translate(120%, 0);
    }

    @media (max-width: 450px) {
      transform: translate(100%, 0);
    }

    @media (max-width: 430px) {
      transform: translate(50%, -70%);
      z-index: 10;

      width: 300px;
      height: 300px;

      z-index: -10;

      opacity: 0.2;
    }
  }

  .title-desc-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    max-width: 600px;
    height: min-content;

    background-color: #ffffff;
    border: 3px solid #165cac;

    position: relative;

    padding: 30px 36px;

    border-radius: 40px;

    @media (max-width: 768px) {
      max-width: 500px;
      margin-right: 120px;
    }

    @media (max-width: 430px) {
      padding: 24px 24px;
      // border-radius: 26px;
      gap: 12px;

      margin-right: 0;
      margin-left: 0;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      height: auto;
      width: auto;

      @media (max-width: 430px) {
        align-items: center;
        gap: 8px;
      }

      .story-star {
        width: 30px;
        height: 30px;

        @media (max-width: 430px) {
          width: 20px;
          height: 20px;
        }
      }
    }

    .title {
      // color: #262626;

      font-family: "Baloo 2";
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px;
      letter-spacing: -0.93px;

      @media (max-width: 768px) {
        font-size: 30px;
        line-height: 30px;
      }

      @media (max-width: 430px) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    .desc {
      display: flex;
      flex-direction: column;
      gap: 8px;

      color: #262626;

      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
      letter-spacing: -0.26px;

      @media (max-width: 430px) {
        font-size: 15px;
        line-height: 21px;
      }
    }
  }

  .outline-one {
    width: 100%;
    height: 100%;

    background-color: #d573ff;
    border: solid 3px #b400ff;

    position: absolute;
    left: 18px;
    bottom: -18px;

    z-index: -2;

    border-radius: 52px;
  }

  .outline-two {
    width: 100%;
    height: 100%;

    background-color: #ffffff;
    border: solid 2px #b400ff;

    position: absolute;
    left: 32px;
    bottom: -32px;

    z-index: -3;

    border-radius: 62px;
  }
}

.reverse {
  flex-direction: row-reverse;
  justify-content: flex-start;

  .story-img {
    left: -120px;

    @media (max-width: 768px) {
      transform: translate(10%, 0);
    }

    @media (max-width: 430px) {
      transform: translate(30%, -70%);
    }
  }

  .title-desc-wrapper {
    background-color: #ffffff;
    border: 3px solid #ac2d16;

    @media (max-width: 768px) {
      margin-left: 120px;
      margin-right: 0;
    }

    @media (max-width: 430px) {
      margin-right: 0;
      margin-left: 0;
    }

    .outline-one {
      left: -18px !important;
      bottom: -18px !important;

      border: solid 3px #f03147;
      background-color: #ff808f;
    }

    .outline-two {
      left: -32px;
      bottom: -32px !important;

      border: solid 2px #f03147;
    }
  }
}

.lp-nft-story-main {
  // overflow: hidden;
  z-index: -1;
  margin-bottom: 40px;
  padding-bottom: 40px;

  width: 100%;

  position: relative;
}

.lp-nft-story-bg-outline {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);

  border: solid 2px #262626;
  border-radius: 240px;

  width: 60%;
  height: 80%;

  z-index: -5;
}

.banner-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  max-width: 1200px;
  height: auto;

  margin: auto;

  padding: 24px;

  border-radius: 26px;

  background: rgb(27, 120, 231);
  background: linear-gradient(153deg, #1b78e7 0%, #2b9bc2 100%);

  @media screen and (max-width: 480px) {
    padding: 16px;
    border-radius: 16px;
  }

  .title {
    width: 100%;

    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    line-height: 23px;
    font-weight: 600;

    color: #ffffff;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  .normal {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 21px;
    font-weight: 400;

    color: #ffffff;
  }

  .bold {
    font-weight: 600;
    color: #ffffff;
  }

  .banner-sub-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 12px;
    }

    .sub-title {
      width: 100%;
      flex-grow: 1;
      align-self: stretch;

      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      line-height: 20px;
      font-weight: 400;

      color: #ffffff;
      background-color: #254b9c;

      padding: 12px 16px;

      border-radius: 12px;
    }
  }
}
