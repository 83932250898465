.marquee-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media only screen and (min-width: 744px) {
    gap: 16px;
  }
  @media only screen and (min-width: 1440px) {
    gap: 28px;
  }
  @media only screen and (min-width: 1920px) {
    /*  same as above */
  }
}

.marquee-img {
  background-size: contain;
  background-repeat: no-repeat;
  width: 160px;
  height: 160px;
  margin-right: 12px;

  border-radius: 26px;

  &-1 {
    background-image: url("../../../assets/marquee-mock-1.png");
  }
  &-2 {
    background-image: url("../../../assets/marquee-mock-2.png");
  }
  &-3 {
    background-image: url("../../../assets/marquee-mock-3.png");
  }
  &-4 {
    background-image: url("../../../assets/marquee-mock-4.png");
  }
  &-5 {
    background-image: url("../../../assets/marquee-mock-5.png");
  }
  &-6 {
    background-image: url("../../../assets/marquee-mock-6.png");
  }

  @media only screen and (min-width: 744px) {
    margin-right: 16px;
  }
  @media only screen and (min-width: 1440px) {
    width: 200px;
    height: 200px;
    margin-right: 36px;
  }
}
