.nft-details {
  display: flex;
  width: 100%;
  max-width: 420px;
  padding-bottom: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .nft-details-image {
    width: 420px;
    height: 420px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .nft-details-info {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .nft-details-info-name {
      text-align: center;

      p {
        color: var(--primary-color);
        font-family: "Baloo 2";
        font-size: 30px;
        font-weight: 700;
        line-height: 30px; /* 100% */
        letter-spacing: -0.53px;
      }
    }

    .nft-details-info-traits {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 0px 24px;

      .nft-details-info-traits-heading {
        text-align: center;

        span {
          color: var(--primary-color);
          font-family: Inter;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px; /* 100% */
          letter-spacing: -0.18px;
        }
      }

      .nft-details-info-traits-list {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        .nft-details-single-trait {
          width: 116px;
          height: 89px;
          display: flex;
          padding: 16px 24px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;
          border-radius: 12px;
          background: var(--quinary-color);
          text-align: center;

          .nft-details-single-trait-type {
            color: var(--secondary-color);
            font-family: Inter;
            font-size: 13px;
            font-weight: 500;
            line-height: 13px; /* 100% */
            letter-spacing: -0.04px;
          }

          .nft-details-single-trait-value {
            color: var(--primary-color);
            font-family: Inter;
            font-size: 15px;
            font-weight: 500;
            line-height: 15px; /* 100% */
            letter-spacing: -0.13px;
          }
        }
      }
    }

    
  }

  .switch-chain-button {
    width: 248px;
    padding: 16px 36px;

    .switch-chain-button-text {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .pending-button {
    width: 248px;
    padding: 16px 24px;

    .pending-button-text {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .nft-details-buttons {
    display: flex;
    justify-content: center;
    // align-self: stretch;
    gap: 16px;
    margin: 0 24px;

    .nft-details-cancel-button {
      display: flex;
      height: 56px;
      padding: 0px 24px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      border-radius: 20px;
      background: var(--quaternary-color);

      span {
        color: var(--secondary-color);
        text-align: center;
        font-family: "Baloo 2";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 100% */
        letter-spacing: -0.46px;
      }
    }

    .nft-details-mint-button {
      width: 187px;
      display: flex;
      height: 56px;
      padding: 16px 36px;
      justify-content: center;
      align-items: center;
      
      gap: 12px;
      border-radius: 20px;
      background: var(--primary-color);

      span {
        color: #FFF;
        text-align: center;
        font-family: "Baloo 2";
        font-size: 18px;
        font-weight: 600;
        line-height: 18px; /* 100% */
        letter-spacing: -0.46px;
      }
    }
  }

  // @media only screen and (max-width: 744px) {

  // }
    
  @media only screen and (max-width: 430px) {
    
    .nft-details-image {
      width: 100%;
      height: 100%;
    }

    .nft-details-info {
      display: flex;
      flex-direction: column;
      gap: 24px;
  
      .nft-details-info-name {
        text-align: center;
  
        p {
          font-size: 30px;
          font-weight: 700;
          line-height: 30px; /* 100% */
          letter-spacing: -0.53px;
        }
      }
  
      .nft-details-info-traits {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 0px 16px;
  
        .nft-details-info-traits-heading {
          text-align: center;
  
          span {
            color: var(--primary-color);
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px; /* 100% */
            letter-spacing: -0.18px;
          }
        }
  
        .nft-details-info-traits-list {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          justify-content: center;

          .nft-details-single-trait {
            width: 114px;
            height: 89px;
            display: flex;
            padding: 16px 24px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            border-radius: 12px;
            background: var(--quinary-color);
            text-align: center;
  
            .nft-details-single-trait-type {
              color: var(--secondary-color);
              font-family: Inter;
              font-size: 13px;
              font-weight: 500;
              line-height: 13px; /* 100% */
              letter-spacing: -0.04px;
            }
  
            .nft-details-single-trait-value {
              color: var(--primary-color);
              font-family: Inter;
              font-size: 15px;
              font-weight: 500;
              line-height: 15px; /* 100% */
              letter-spacing: -0.13px;
            }
          }
        }
      }
  
      
    }

    .nft-details-buttons {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      margin: 0 16px;
  
      .nft-details-cancel-button {
        display: flex;
        height: 56px;
        padding: 0px 24px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        border-radius: 20px;
        background: var(--quaternary-color);
  
        span {
          color: var(--secondary-color);
          text-align: center;
          font-family: "Baloo 2";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px; /* 100% */
          letter-spacing: -0.46px;
        }
      }
  
      .nft-details-mint-button {
        display: flex;
        height: 56px;
        padding: 0px 24px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        border-radius: 20px;
        background: var(--primary-color);
  
        span {
          color: #FFF;
          text-align: center;
          font-family: "Baloo 2";
          font-size: 18px;
          font-weight: 600;
          line-height: 18px; /* 100% */
          letter-spacing: -0.46px;
        }
      }
    }
  
    // .pending-button {
    //   width: 248px;
    //   padding: 16px 24px;
  
    //   .pending-button-text {
    //     font-size: 18px;
    //     font-weight: 600;
    //   }
    // }
  }
  
  @media only screen and (max-width: 320px) {

    .nft-details-image {
      // width: 100%;
      // height: 288px;
    }

    .nft-details-info {
      display: flex;
      flex-direction: column;
      gap: 16px;
  
      .nft-details-info-name {
        text-align: center;
  
        p {
          font-size: 30px;
          font-weight: 700;
          line-height: 30px; /* 100% */
          letter-spacing: -0.53px;
        }
      }
  
      .nft-details-info-traits {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 0px 16px;
  
        .nft-details-info-traits-heading {
          text-align: center;
  
          span {
            color: var(--primary-color);
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px; /* 100% */
            letter-spacing: -0.18px;
          }
        }
  
        .nft-details-info-traits-list {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
  
          .nft-details-single-trait {
            width: 120px;
            height: 89px;
            display: flex;
            padding: 16px 24px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            border-radius: 12px;
            background: var(--quinary-color);
            text-align: center;
  
            .nft-details-single-trait-type {
              color: var(--secondary-color);
              font-family: Inter;
              font-size: 13px;
              font-weight: 500;
              line-height: 13px; /* 100% */
              letter-spacing: -0.04px;
            }
  
            .nft-details-single-trait-value {
              color: var(--primary-color);
              font-family: Inter;
              font-size: 15px;
              font-weight: 500;
              line-height: 15px; /* 100% */
              letter-spacing: -0.13px;
            }
          }
        }
      }
  
      
    }

    .nft-details-buttons {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      margin: 0 16px;
  
      .nft-details-cancel-button {
        display: flex;
        height: 56px;
        padding: 0px 24px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        border-radius: 20px;
        background: var(--quaternary-color);
  
        span {
          color: var(--secondary-color);
          text-align: center;
          font-family: "Baloo 2";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px; /* 100% */
          letter-spacing: -0.46px;
        }
      }
  
      .nft-details-mint-button {
        display: flex;
        height: 56px;
        padding: 0px 24px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        border-radius: 20px;
        background: var(--primary-color);
  
        span {
          color: #FFF;
          text-align: center;
          font-family: "Baloo 2";
          font-size: 18px;
          font-weight: 600;
          line-height: 18px; /* 100% */
          letter-spacing: -0.46px;
        }
      }
    }
  
    // .pending-button {
    //   width: 248px;
    //   padding: 16px 24px;
  
    //   .pending-button-text {
    //     font-size: 18px;
    //     font-weight: 600;
    //   }
    // }
  }
}