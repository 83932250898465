.pending-button {
  display: flex;
  width: 246px;
  // height: 56px;
  padding: 16px 24px;
  justify-content: center;
  align-self: center;
  border-radius: 20px;
  background: var(--quinary-color);
  cursor: not-allowed;

  .pending-button-text {
    color: var(--tertiary-color);
    text-align: center;
    font-family: "Baloo 2";
    font-size: 18px;
    font-weight: 600;
    line-height: 18px; /* 100% */
    letter-spacing: -0.46px;
  }
}

.mint-button {
  display: flex;
  width: 246px;
  height: 56px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  background: var(--primary-color);
  align-self: center;

  .mint-button-text {
    color: #fff;
    text-align: center;
    font-family: "Baloo 2";
    font-size: 20px;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: -0.46px;
  }
}

.disabled {
  background: var(--quinary-color);
  cursor: not-allowed;

  .mint-button-text {
    color: var(--tertiary-color);
    text-align: center;
    font-family: "Baloo 2";
    font-size: 20px;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: -0.46px;
  }
}
