.lp-home-hero {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
}

.lp-home-hero-video-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: 100%;
    height: 100%;
    object-fit: cover;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// .lp-home-hero-header {
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   gap: 16px;
//   height: 100vh;
//   padding-bottom: 16px;

//   @media only screen and (min-width: 744px) {
//     padding-bottom: 60px;
//   }
//   @media only screen and (min-width: 1440px) {
//     padding-bottom: 120px;
//   }
// }

// .lp-home-hero-heading {
//   display: flex;
//   flex-direction: column;
//   flex-wrap: wrap;
//   gap: 12px;
//   z-index: 1;

//   color: #fff;
//   text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.6);
//   font-family: "Lilita One";
//   font-size: 60px;
//   font-weight: 400;
//   line-height: 56px;
//   text-transform: uppercase;

//   p {
//     font-size: 15px;
//     font-weight: 500;
//     line-height: 21px;
//     text-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
//     letter-spacing: -0.04px;
//     color: #fff;
//   }

//   @media only screen and (min-width: 744px) {
//     display: flex;
//     flex-direction: column;

//     h1 {
//       font-size: 45px;
//       line-height: 45px;
//     }
//     h1:nth-child(2) {
//       font-size: 64px;
//       line-height: 64px;
//     }
//   }
//   @media only screen and (min-width: 1440px) {
//     h1 {
//       font-size: 76px;
//       line-height: 76px;
//     }
//     h1:nth-child(2) {
//       font-size: 106px;
//       line-height: 106px;
//     }
//   }
// }

// .lp-home-hero-subheading {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   gap: 2px;
//   z-index: 1;

//   width: 100%;

//   color: #fff;
//   text-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
//   font-weight: 500;
//   line-height: 18px;
//   font-size: 13px;
//   letter-spacing: -0.04px;

//   .lp-home-hero-link {
//     padding: 4px 8px;
//     background: rgba(38, 38, 38, 0.6);
//     border-radius: 16px;

//     a {
//       color: #fff;
//       text-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
//       font-size: 12px;
//       font-weight: 600;
//       letter-spacing: 0.01px;
//       text-decoration: none;

//       white-space: nowrap;

//       &:hover {
//         text-decoration: underline;
//       }
//     }
//   }

//   @media only screen and (min-width: 744px) {
//     gap: 0;

//     p {
//       font-size: 16px;
//       line-height: 16px;
//       display: inline;
//     }
//     .lp-home-hero-link {
//       padding: 8px 16px;

//       a {
//         font-size: 15px;
//         line-height: 15px;
//       }
//     }
//   }
//   @media only screen and (min-width: 1440px) {
//     p {
//       font-size: 20px;
//       line-height: 20px;
//     }
//     .lp-home-hero-link {
//       padding: 8px 16px;

//       a {
//         font-size: 18px;
//         line-height: 18px;
//       }
//     }
//   }
//   @media only screen and (min-width: 1920px) {
//     /* same as above */
//   }
// }

// .lp-home-hero-wrapper {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

.lp-home-hero-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 24px;
  height: 100vh;

  padding-bottom: 60px;

  @media (max-width: 430px) {
    gap: 16px;

    padding-bottom: 100px;
  }

  a {
    text-decoration: none;
    -webkit-text-decoration-line: none;
    z-index: 1;
    // text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);

    width: max-content;
    display: flex;

    .lp-home-hero-whitepaper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;

      padding: 20px 60px;

      color: #ffffff;
      font-family: Inter;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.26px;

      border-radius: 26px;
      border: 2px solid #ffffff;
      background: linear-gradient(36deg, rgb(20, 19, 71) 0%, rgb(61, 44, 218) 100%);

      @media (max-width: 768px) {
        padding: 18px 30px;
        font-size: 24px;
      }

      @media (max-width: 430px) {
        font-size: 22px;

        padding: 12px 26px;
        gap: 10px;
        border-radius: 20px;
      }

      .mouse-click-whitepaper-icon {
        width: 26px;

        @media (max-width: 430px) {
          width: 22px;
        }
      }
    }
  }

  .lp-home-hero-heading {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px;
    z-index: 1;

    color: #fff;
    text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
    font-family: "Lilita One";
    font-size: 110px;
    font-weight: 400;
    line-height: 90px;
    letter-spacing: 0.4px;

    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 90px;
      line-height: 80px;
    }

    @media (max-width: 576px) {
      font-size: 70px;
      line-height: 60px;
    }

    @media (max-width: 430px) {
      font-size: 60px;
      line-height: 52px;

      text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 360px) {
      font-size: 50px;
      line-height: 46px;
    }
  }

  .lp-home-hero-subheading {
    width: fit-content;
    z-index: 1;

    color: #fff;
    background: rgb(75, 67, 236);
    background: linear-gradient(60deg, #7069ee 0%, #ae74f1 100%);

    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.33px;

    padding: 12px 24px;

    border-radius: 16px;

    @media (max-width: 576px) {
      padding: 10px 16px;

      border-radius: 10px;

      font-size: 15px;
      line-height: 21px;
    }

    @media (max-width: 430px) {
      width: auto;
      padding: 10px 16px;

      border-radius: 10px;

      font-size: 15px;
      line-height: 21px;
    }
  }
}
