.accordion {
  border-top: 1px solid var(--quaternary-color);
  border-bottom: 1px solid var(--quaternary-color);
  display: flex;
  flex-direction: column;

  &-item {
    padding: 24px 16px;

    @media only screen and (min-width: 744px) {
      padding: 36px 24px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--quaternary-color);
    }
    &-header {
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;

      &-title {
        color: var(--secondary-color);
        font-family: "Inter";
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.13px;
        font-weight: 500;

        @media only screen and (min-width: 744px) {
          font-size: 16px;
          line-height: 21px;
          letter-spacing: -0.18px;
        }
      }

      @media only screen and (min-width: 744px) {
        gap: 24px;
      }
    }
    &-text {
      font-family: "Inter";
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.13px;
      color: var(--primary-color);

      @media only screen and (min-width: 744px) {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.18px;
      }
    }
    &-description {
      min-height: 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &-description-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 0fr;
      overflow: hidden;
      transition: var(--transition);

      @media only screen and (min-width: 744px) {
        grid-template-columns: 1fr;
      }
    }
    &.open {
      .accordion-item-description-wrapper {
        grid-template-rows: 1fr;
        margin-top: 24px;
      }
    }
  }
}
