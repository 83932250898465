// .card {
//   border-radius: 26px;
//   border: 1px solid var(--quaternary-color);
//   min-width: 140px;

//   @media (max-width: 430px) {
//     display: flex;
//   }

//   &-header {
//     background-color: var(--quaternary-color);
//     height: 120px;
//     border-top-left-radius: 26px;
//     border-top-right-radius: 26px;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     @media (max-width: 430px) {
//       width: 200px;
//       border-top-right-radius: 0;
//       border-bottom-left-radius: 26px;
//     }

//     &-icon {
//       width: 80px;
//       height: 80px;
//     }
//   }
//   &-body {
//     display: flex;
//     flex-direction: column;
//     text-align: center;
//     padding: 24px 8px;
//     gap: 12px;

//     @media (max-width: 430px) {
//       width: 100%;

//       justify-content: center;
//       gap: 6px;

//       padding: 8px 16px;
//     }
//   }
//   &-title {
//     font-family: "Baloo 2";
//     font-size: 18px;
//     font-weight: 700;
//     line-height: 16px; /* 88.889% */
//     letter-spacing: -0.264px;

//     @media (max-width: 430px) {
//       text-align: left;
//     }
//   }
//   &-description {
//     font-family: "Inter";
//     font-size: 13px;
//     font-weight: 400;
//     line-height: 18px; /* 138.462% */
//     letter-spacing: -0.04px;
//     color: var(--secondary-color);

//     @media (max-width: 430px) {
//       text-align: left;
//     }

//     a {
//       font-size: inherit;
//     }
//   }
//   &-link {
//     font-family: "Inter";
//     font-size: 13px;
//     font-weight: 400;
//     line-height: 18px; /* 138.462% */
//     letter-spacing: -0.04px;
//     color: var(--cta-color);
//     text-decoration: none;

//     &:hover {
//       text-decoration: underline;
//     }
//   }

//   @media only screen and (min-width: 744px) {
//     &-header {
//       height: 180px;

//       &-icon {
//         width: 165px;
//         height: 165px;
//       }
//     }
//     &-body {
//       padding: 46px 24px;
//     }
//     &-title {
//       font-size: 24px;
//       line-height: 24px;
//     }
//     &-description {
//       font-size: 16px;
//       line-height: 21px;
//     }
//   }
//   @media only screen and (min-width: 1440px) {
//     &-body {
//       padding: 46px 24px;
//     }
//   }
// }

// .card-wrapper {
//   display: grid;
//   grid-template-columns: 1fr;
//   gap: 8px;

//   @media (max-width: 430px) {
//     gap: 16px;
//   }

//   @media only screen and (min-width: 744px) {
//     grid-template-columns: 1fr 1fr;
//     gap: 24px;
//   }
//   @media only screen and (min-width: 1440px) {
//     grid-template-columns: 1fr 1fr 1fr 1fr;
//     gap: 24px;
//   }
// }

.card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-gap: 16px;
}

.card {
  width: 100%;
  display: flex;
  flex-direction: column;

  border-radius: 36px;
  overflow: hidden;

  border: 1px solid #eaeaea;

  @media (max-width: 430px) {
    flex-direction: row;

    border-radius: 26px;
  }

  .card-header {
    background-color: #f1f1f1;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 12px;

    @media (max-width: 430px) {
      .card-header-icon {
        width: 120px;
        height: 100px;
      }
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    text-align: center;

    padding: 0 24px;

    height: 170px;

    @media (max-width: 430px) {
      align-items: flex-start;
      text-align: left;

      gap: 6px;

      padding: 0 16px;
    }

    .card-title {
      color: #262626;
      text-align: center;
      font-family: "Baloo 2";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.264px;

      @media (max-width: 430px) {
        font-size: 20px;
        text-align: left;
      }
    }

    .card-description {
      color: #6b6b6b;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.18px;

      @media (max-width: 430px) {
        font-size: 15px;
      }
    }
  }
}
