.nft-carousel {
  display: flex;
  max-width: 676px;
  justify-content: center;
  height: 284px;
  
  .nft-carousel-viewer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    user-select: none;

    img {
      transition: transform 0.5s ease;
    }

    .nft-carousel-prev {
      width: 42px;
      height: 74px;
      display: flex;
      padding: 24px 8px;
      justify-content: center;
      align-items: center;
      align-self: center;
      cursor: pointer;

      border-radius: 10px;
      border: 1px solid var(--tertiary-color);   
    }

    
    .nft-carousel-prev-image {
      width: 120px;
      height: 120px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 120px;
        opacity: 0.5;
      }
    }

    .nft-carousel-next-image {
      width: 120px;
      height: 120px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 120px;
        opacity: 0.5;
      }
    }
    

    .nft-carousel-next {
      width: 42px;
      height: 74px;
      display: flex;
      padding: 24px 8px;
      justify-content: center;
      align-items: center;
      align-self: center;
      cursor: pointer;

      border-radius: 10px;
      border: 1px solid var(--tertiary-color);
    }

    // override NFTAndCollectionPair style
    .nft-pair-nft-image {
      bottom: 20px;
    }
  }

  // @media only screen and (max-width: 430px) {
    
  // }
 
  // @media only screen and (max-width: 320px) {

  // }
}
