@use 'colors' as *;

html[data-theme="dark"] {
  --primary-color: #{$white-500};
  --secondary-color: #{$secondary-500};
  --tertiary-color: #{$tertiary-500};
  --quaternary-color: #{$quaternary-500};

  --bg-color: #{$primary-500};
  --cta-color: #{$cta-500};
}

html[data-theme="light"] {
  --primary-color: #{$primary-500};
  --primary-80-color: #{$primary-500-80};
  --primary-R-color: #{$primary-500-R};

  --secondary-color: #{$secondary-500};
  --secondary-R-color: #{$secondary-500-R};

  --tertiary-color: #{$tertiary-500};
  --quaternary-color: #{$quaternary-500};
  --quinary-color: #{$quinary-500};

  --bg-color: #{$white-500};

  --cta-color: #{$cta-500};
  --outline-15-color: #{$outline-15};
  --opacity-80-color: #{$opacity-80};

  --positive-color: #{$positive-500};
  --warning-color: #{$warning-500};
}