
.eligibility {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  border-radius: 16px;
  background: var(--quinary-color);

  .eligibility-left {
    display: flex;
    align-items: center;
    gap: 12px;

    .eligibility-text {
      color: var(--primary-color);
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 18px; /* 100% */
      letter-spacing: -0.26px;
    }
  }

  .entitlement-text {
    color: var(--primary-color);

    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: -0.32px;
  }

  // @media only screen and (max-width: 1440px) {
    
  // }

  // @media only screen and (max-width: 744px) {

  // }

  @media only screen and (max-width: 430px) {
    .eligibility-left {
      div {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}

.status {
  display: flex;
  height: 34px;
  width: 56px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--positive-color);

  color: var(--bg-color);
  text-align: center;

  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px; /* 100% */
  letter-spacing: -0.18px;
}


// @media only screen and (max-width: 1440px) {
      
// }

// @media only screen and (max-width: 744px) {

// }

// @media only screen and (max-width: 430px) {
  
// }

// @media only screen and (max-width: 320px) {

// }