// Helpers
.lp-section {
  max-width: calc(100% - 32px);
  margin: auto;

  @media only screen and (min-width: 744px) {
    max-width: calc(100% - 72px);
  }
  @media only screen and (min-width: 1440px) {
    max-width: 1200px;
  }

  &-full-width {
    max-width: 100vw;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;

    @media only screen and (min-width: 744px) {
      margin-bottom: 36px;
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 36px;
    gap: 26px;

    @media only screen and (min-width: 744px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      > :not(:only-child) {
        flex: 1 1 0px;
      }
    }
  }
}

.lp-link-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}
