// .app-footer {
//   width: 100%;
//   max-width: 1200px;
//   padding-top: 40px;
//   padding-bottom: 80px;
//   padding-left: 16px;
//   padding-right: 16px;
//   max-width: 1920px;
//   margin: auto;

//   @media only screen and (min-width: 744px) {
//     padding-top: 80px;
//     padding-bottom: 240px;
//     padding-left: 60px;
//     padding-right: 60px;
//   }
//   @media only screen and (min-width: 1440px) {
//     padding-top: 100px;
//     padding-bottom: 170px;
//     padding-left: 120px;
//     padding-right: 120px;
//   }
//   @media only screen and (min-width: 1920px) {
//     padding-top: 180px;
//     padding-bottom: 360px;
//     padding-left: 360px;
//     padding-right: 360px;
//   }
// }

// .app-footer-wrapper {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 24px;
//   max-width: 1920px;
//   margin: auto;

//   @media only screen and (min-width: 744px) {
//     flex-direction: row;
//     justify-content: space-between;
//   }
// }

// .app-footer-logo-wrapper {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 48px;

//   @media only screen and (min-width: 744px) {
//     align-items: flex-start;
//   }
//   @media only screen and (min-width: 744px) {
//     gap: 36px;
//   }
// }

// .app-footer-icon-wrapper {
//   display: flex;
//   gap: 36px;
// }

// .app-footer-link-wrapper {
//   display: flex;
//   gap: 24px;
//   width: 100%;
//   flex: 1;

//   @media only screen and (min-width: 744px) {
//     flex: 0.7;
//   }
//   @media only screen and (min-width: 1440px) {
//     flex: 0;
//   }

//   ul {
//     flex: 1 0 0;
//   }

//   li {
//     font-family: "Inter";
//     font-size: 15px;
//     font-weight: 400;
//     line-height: 20px;
//     letter-spacing: -0.13px;
//     padding-top: 16px;
//     padding-bottom: 16px;
//     color: #6b6b6b;
//     min-width: 100px;

//     a {
//       color: inherit;
//       text-decoration: none;
//     }

//     @media only screen and (min-width: 744px) {
//       font-size: 16px;
//       line-height: 21px;
//       letter-spacing: -0.18px;
//     }

//     @media only screen and (min-width: 1440px) {
//       min-width: 120px;
//     }
//   }
// }

// .app-footer-copyright {
//   padding-top: 36px;
// }

// footer {
//   position: relative;
//   overflow-x: hidden;

//   .app-footer-bg {
//     position: absolute;
//     bottom: 0;
//     left: -50vw;
//     width: 200vw;
//     height: auto;
//     z-index: -1;

//     @media only screen and (min-width: 744px) {
//       width: 150vw;
//       left: -45vw;
//     }
//     @media only screen and (min-width: 1440px) {
//       width: 100vw;
//       left: 0;
//       height: 100%;
//     }
//   }
// }

.lp-dark-bg-customize {
  .app-footer-copyright {
    color: #ddd8f0 !important;

    span {
      color: #ffffff !important;
    }
  }

  .app-footer-bg {
    opacity: 0.7;
  }
}

footer {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 460px;

  margin-top: 160px;

  position: relative;

  z-index: 1;

  @media (max-width: 430px) {
    height: 460px;

    margin-top: 60px;
  }

  .app-footer-bg {
    width: 100%;
    height: 100%;

    position: absolute;
    bottom: 0;

    overflow: visible;
    object-fit: cover;
    z-index: -1;
  }

  .app-footer-content {
    display: flex;
    flex-direction: column;
    gap: 40px;

    width: 100%;
    max-width: 1200px;

    margin: 36px;

    .app-footer-top {
      display: flex;
      justify-content: space-between;

      @media (max-width: 430px) {
        flex-direction: column-reverse;
        align-items: center;
        gap: 50px;
      }

      .app-footer-logo-wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @media (max-width: 430px) {
          align-items: center;
          flex-direction: column-reverse;
          gap: 50px;
        }

        .TorumTextLogo {
          width: 180px;

          @media (max-width: 430px) {
            width: 140px;
          }
        }

        .app-footer-icon-wrapper {
          display: flex;
          gap: 24px;
        }
      }

      .app-footer-link-wrapper {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;

        @media (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          white-space: nowrap;
          gap: 8px;
        }

        @media (max-width: 430px) {
          grid-template-columns: repeat(3, 1fr);
          white-space: nowrap;
          gap: 12px;
        }

        .footer-button {
          height: 36px;

          padding: 0 16px;

          color: #6b6b6b;

          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.18px;

          display: flex;
          align-items: center;
          justify-content: flex-start;

          text-decoration: none;

          @media (max-width: 430px) {
            font-size: 15px;
            line-height: 15px;
            justify-content: center;
          }
        }
      }
    }

    .app-copyright-support-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .app-footer-copyright {
        color: #838383;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: -0.18px;

        @media (max-width: 430px) {
          width: 270px;

          text-align: center;
          font-size: 13px;
          line-height: 19px;

          margin: 0 auto;
        }

        span {
          color: #be44f3;
          font-weight: 500;
        }
      }
    }
  }
}
