.carousel {
  display: flex;
  gap: 46px;
  flex-direction: column;

  @media only screen and (min-width: 1440px) {
    flex-direction: row;
  }
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 24px;
  flex: 1;

  @media only screen and (min-width: 744px) {
    flex-direction: column;
    align-items: center;
    gap: 36px;

    .carousel-viewport-vertical {
      flex: 1;
    }
  }
  @media only screen and (min-width: 1440px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 52px;

    .carousel-viewport-vertical {
      flex: 1;
      min-height: 440px;

      .carousel-slide {
        justify-content: center;
      }
    }
  }
}

.carousel-viewport {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  width: 100%;
  height: 100%;

  .carousel-slide {
    transition: transform 0.5s ease, opacity 0.5s ease-out;
    transform: translateX(var(--left));
    opacity: 0;

    img {
      width: 100%;
      height: 100%;
    }

    &.active {
      opacity: 1;
    }
  }

  @media only screen and (min-width: 768px) {
    width: 440px;
    height: 440px;
  }

  @media only screen and (min-width: 1440px) {
    width: 440px;
    height: 440px;
  }
}

.carousel-viewport-vertical,
.swiper {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  width: 100%;
  min-height: 200px;
  height: var(--height);
  touch-action: none;

  .carousel-slide {
    margin-top: 4px;
    height: auto;
    transition: opacity 0.5s ease-out;
    justify-content: flex-start;
    opacity: 0;

    &.active,
    &.swiper-slide-active {
      opacity: 1;
    }
  }

  @media only screen and (min-width: 1440px) {
    .carousel-slide {
      justify-content: center;
    }
  }
}

.carousel-slide {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &-description p {
    font-family: "Inter";
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.13px;
    color: var(--primary-color);

    @media only screen and (min-width: 744px) {
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.18px;
    }
  }
}

.carousel-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;

  li {
    width: 60px;
    height: 60px;
    border-radius: 16px;
    border: 1px solid transparent;
    padding: 6px;
    color: #b3b3b3;
    opacity: 0.3;
    cursor: pointer;
    transition: var(--transition);
    border: 1px solid transparent;

    &:hover {
      color: #262626;
      opacity: 0.3;
    }
    &:nth-child(1) {
      &.active {
        opacity: 1;
        color: #ff761c;
        border-color: #ff761c;
      }
    }
    &:nth-child(2) {
      &.active {
        opacity: 1;
        color: #a017d9;
        border-color: #a017d9;
      }
    }
    &:nth-child(3) {
      &.active {
        opacity: 1;
        color: #93a523;
        border-color: #93a523;
      }
    }
    &:nth-child(4) {
      &.active {
        opacity: 1;
        color: #1e38c0;
        border-color: #1e38c0;
      }
    }
  }

  @media only screen and (min-width: 744px) {
    gap: 16px;
  }
  @media only screen and (min-width: 1440px) {
    flex-direction: column;
    width: auto;
    justify-self: flex-end;
  }
}

.carousel-container .swiper {
  width: 100%;
  height: 100%;
  flex: 1;

  @media only screen and (min-width: 768px) {
    height: 300px;
  }

  @media only screen and (min-width: 1440px) {
    height: 440px;
  }
}

.carousel-container .swiper-pagination {
  display: none;
}
