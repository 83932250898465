
// custom classname applied on ToastContainer in AppLayout.
// used to override the default CSS of Toast.

.my-toast-container {
  padding: 0;

  .Toastify__toast-theme--light {
    background: var(--quinary-color);
    border-radius: 16px
  }

  .Toastify__toast-body {
    padding: 16px;
  } 
}