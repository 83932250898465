.lp-creators-lab {
  display: flex;
  flex-direction: column;

  overflow: visible;

  position: relative;
}

.lp-hero-creators-lab-hero {
  width: 100%;
  height: 980px;

  display: flex;
  justify-content: center;

  position: relative;
  overflow: hidden;

  z-index: 1;
  background-color: #000000;

  @media (max-width: 768px) {
    height: 900px;
  }

  @media (max-width: 430px) {
    height: 900px;
  }

  .lp-creators-lab-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;

    @media (max-width: 768px) {
      object-position: 60% 50%;
      overflow: visible;
    }

    @media (max-width: 430px) {
      object-position: 70% 50%;
      overflow: visible;
    }
  }

  .lp-hero-creators-lab-container {
    position: absolute;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;

    background: linear-gradient(90deg, rgba(0, 0, 0, 0.85) 25%, rgba(0, 0, 0, 0) 100%);

    @media (max-width: 430px) {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.4) 100%);
    }
  }

  .lp-hero-creators-lab-content {
    width: 100%;
    max-width: 1200px;

    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;

    margin: 36px;

    @media (max-width: 430px) {
      margin: 16px;
      justify-content: flex-start;
      margin-top: 160px;
    }

    .creators-lab-logo-container {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (max-width: 430px) {
        align-items: center;
      }

      .coming-soon-wrapper {
        display: flex;
        align-items: center;

        @media (max-width: 430px) {
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          gap: 24px;
        }

        .lp-creators-lab-logo {
          width: 160px;

          @media (max-width: 768px) {
            width: 120px;
          }

          @media (max-width: 430px) {
            width: 100px;
          }
        }

        .lp-coming-soon-text {
          border-radius: 12px;
          border: 4px solid #000000;

          background: #e2e60e;

          width: 100%;
          max-width: 240px;

          padding: 12px 16px;
          margin-left: -16px;

          @media (max-width: 768px) {
            max-width: 180px;
            padding: 10px 12px;
            margin-left: -12px;
          }

          @media (max-width: 430px) {
            max-width: 160px;
            margin: 0;
            border: 2px solid #000000;
          }
        }
      }

      .lp-creators-lab-text {
        width: 100%;
        max-width: 600px;

        @media (max-width: 768px) {
          max-width: 400px;
        }

        @media (max-width: 430px) {
          max-width: 300px;
        }
      }
    }

    #lp-description-text {
      font-family: "Inter";
      font-size: 17px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.18px;
      color: white;

      width: 100%;
      max-width: 600px;

      span {
        font-weight: 600;
      }

      @media (max-width: 430px) {
        font-size: 15px;
        line-height: 23px;
        text-align: center;
      }
    }

    #lp-description-text-special {
      font-family: Inter;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: -0.33px;
      color: rgb(255, 255, 255);

      @media (max-width: 430px) {
        font-size: 20px;
        line-height: 26px;
        text-align: center;
      }
    }

    .lp-social-interaction-container {
      display: flex;
      flex-direction: column;
      gap: 24px;

      margin-top: 24px;

      .lp-social-interaction-icons {
        display: flex;
        gap: 16px;

        a {
          width: 100%;
          max-width: 400px;
          background-color: #ffffff;

          text-decoration: none;
          -webkit-text-decoration-line: none;

          padding: 16px 24px;
          border-radius: 16px;

          color: #262626;
          text-align: center;
          font-family: "Baloo 2";
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 24px;
          letter-spacing: -0.46px;

          transition: all 0.3s ease;

          &:hover {
            background-color: #e5ff00;
            color: #1f072b;
          }

          @media (max-width: 430px) {
            font-size: 20px;
            line-height: 20px;
          }
        }

        @media (max-width: 430px) {
          margin: 0 auto;

          gap: 36px;
        }
      }
    }
  }
}

.lp-creators-lab-body {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;

  overflow: visible;

  background: rgb(49, 88, 91);
  background: linear-gradient(360deg, #1a1b2b 0%, #000000 100%);

  @media (max-width: 430px) {
    padding: 160px 16px 0;
  }

  #lp-creators-lab-big-title {
    font-family: Baloo;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: -2.4px;
    color: #ffffff;

    text-align: center;

    span {
      color: #c745ff;
    }

    @media (max-width: 768px) {
      font-size: 54px;
      line-height: 54px;
    }

    @media (max-width: 430px) {
      font-size: 44px;
      line-height: 44px;
    }
  }

  @media (max-width: 768px) {
    gap: 100px;
  }

  @media (max-width: 430px) {
    padding: 0 16px;
  }

  .lp-creators-lab-container {
    width: calc(100% - 72px);
    max-width: 1200px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 16px;

    z-index: 1;

    @media (max-width: 768px) {
      gap: 24px;
      flex-direction: column;

      &.reverse {
        flex-direction: column-reverse;
      }
    }

    @media (max-width: 430px) {
      width: calc(100% - 32px);
    }
  }

  .lp-creators-lab-card {
    width: 100%;
    max-width: 600px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;

    #lp-creators-lab-info-title {
      font-family: "Baloo";
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: -1.5px;

      text-align: left;

      color: #ffffff;

      @media (max-width: 430px) {
        font-size: 36px;
        line-height: 36px;
      }
    }

    #lp-creators-lab-info-description {
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.18px;

      text-align: left;

      color: #dbdbdb;
      // background-color: #f4f4f4;

      // padding: 12px 24px;
      // border-radius: 16px;
    }
  }

  .lp-creators-lab-info-img {
    width: 100%;
    max-width: 520px;

    margin: 0 auto;

    @media (max-width: 960px) {
      width: 100%;
      max-width: 340px;
    }

    @media (max-width: 768px) {
      width: 100%;
      max-width: 440px;
    }
  }

  .lp-creators-lab-cta {
    width: 100%;
    max-width: 800px;

    margin: 120px 60px 0 60px;

    border-radius: 36px;
    border: 4px solid #3d5aa6;

    background: linear-gradient(263deg, #4063e0 -1.41%, #7996fd 103.31%);

    padding: 36px 120px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    position: relative;

    overflow: visible;

    z-index: 1;

    @media (max-width: 768px) {
      padding: 36px 40px;
      margin-top: 40px;
    }

    @media (max-width: 430px) {
      margin-top: 0;
    }

    .lp-creators-lab-cta-title {
      color: #ffffff;
      text-align: center;
      font-family: Baloo;
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: -0.93px;

      @media (max-width: 430px) {
        font-size: 30px;
        line-height: 30px;
      }
    }

    .lp-creators-lab-cta-desc {
      width: 100%;
      max-width: 400px;

      color: #ffffff;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.18px;

      @media (max-width: 430px) {
        font-size: 15px;
      }
    }

    a {
      width: 100%;
      max-width: 200px;
      background-color: #ffffff;

      text-decoration: none;
      -webkit-text-decoration-line: none;

      padding: 16px 24px;
      border-radius: 16px;

      color: #262626;
      text-align: center;
      font-family: "Baloo 2";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.46px;

      transition: all 0.3s ease;

      &:hover {
        background-color: #262626;
        color: #ffffff;
      }

      @media (max-width: 430px) {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  .cta-img-1 {
    position: absolute;
    width: 100%;
    max-width: 360px;

    left: 0;
    transform: translate(-80%, 0%);

    @media (max-width: 1440px) {
      transform: translate(-60%, 0%);
    }

    @media (max-width: 1200px) {
      transform: translate(-40%, 0%);
    }

    @media (max-width: 1080px) {
      transform: translate(-15%, 50%);
      max-width: 300px;
    }

    @media (max-width: 768px) {
      transform: translate(-12%, 40%);
      max-width: 260px;
    }

    @media (max-width: 680px) {
      display: none;
    }
  }

  .cta-img-2 {
    position: absolute;
    width: 100%;
    max-width: 360px;

    right: 0;
    transform: translate(80%, 0%);

    @media (max-width: 1440px) {
      transform: translate(60%, 0%);
    }

    @media (max-width: 1200px) {
      transform: translate(40%, 0%);
    }

    @media (max-width: 1080px) {
      transform: translate(15%, 50%);
      max-width: 300px;
    }

    @media (max-width: 768px) {
      transform: translate(12%, 40%);
      max-width: 260px;
    }

    @media (max-width: 680px) {
      display: none;
    }
  }
}

.lp-creators-lab-sub-bg {
  width: 100%;

  position: fixed;

  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // bottom: 0;
  top: 6%;

  z-index: 0;

  mix-blend-mode: screen;

  // margin-top: 180px;

  @media (max-width: 768px) {
    transform: rotate(90deg);

    width: auto;

    bottom: 0;
    right: -244px;
  }
}

.dark-bg-cl {
  background-color: #1a1b2b;
  position: absolute;

  height: 100%;
  width: 100%;

  bottom: -620px;

  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  z-index: -1;

  @media (max-width: 430px) {
    bottom: -520px;
  }
}
