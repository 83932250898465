/** 
 * Landing pages common styles 
 */

// lp = landing page, children should be prefixed with lp- to avoid naming clashes in future
.lp {
  &-heading {
    font-family: "Baloo";
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.93px;
    color: var(--primary-color);

    @media only screen and (min-width: 744px) {
      font-size: 36px;
      line-height: 36px;
    }
    @media only screen and (min-width: 1440px) {
      font-size: 38px;
      line-height: 38px;
    }
  }

  &-link {
    font-family: "Inter";
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.13px;
    color: var(--cta-color);
    text-decoration: none;
    transition: var(--transition);

    &:hover {
      text-decoration: underline;
    }
  }
}

/** landing page section */
.lp-home-container {
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media only screen and (min-width: 744px) {
    gap: 80px;
  }
  @media only screen and (min-width: 1440px) {
    gap: 180px;
  }

  .lp-your-nft-container {
    padding-top: 40px;

    @media only screen and (min-width: 744px) {
      padding-top: 80px;
    }
  }
}
