// // @use '../../theme/colors.scss' as *;

.creators-lab-special {
  .app-header-nav {
    background-color: rgb(0, 0, 0, 0.85) !important;
    backdrop-filter: blur(25px);
  }

  .app-header-nav-list-box {
    a {
      color: #6d6d6d !important;
    }
    .active {
      color: #ffffff !important;
    }
  }

  .app-header-connected-container {
    .app-header-connected-network {
      background-color: #262626;
      border: #3b3b3b solid 1px;

      p {
        color: #ffffff;
      }
    }

    .app-header-dropdown {
      background-color: #0f0f0f;

      .app-header-dropdown-item:first-child {
        border-bottom: 1px solid #1a1a1a;
      }

      a {
        color: #dbdbdb;
      }

      .text-red {
        color: #eb004e !important;
      }
    }
  }

  .app-header-connected {
    background-color: #262626 !important;
    border: #3b3b3b solid 1px !important;
    p {
      color: #ffffff !important;
    }

    svg {
      fill: #ffffff !important;
    }
  }
}

.app-header-sticky {
  // position: sticky;
  // position: -webkit-sticky; /* Safari */
  // top: 16px;
  // left: 0;
  // right: 0;
  // bottom: 0;
  z-index: 9;
  width: 100%;
  // height: 100%;

  .app-header-nav {
    position: fixed;
    top: 16px;
    left: 0;
    right: 0;
    margin: auto;

    // height: 100%;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(25px);
  }
}

.app-header {
  // width: 100%;
  // max-width: calc(1200px - 32px);
  // margin: auto;
  // margin-top: 16px;
  // margin-bottom: 16px;
  // height: 100vh;

  position: absolute;

  .app-header-nav {
    width: calc(100% - 32px);
    max-width: 1200px;
    height: 60px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px;

    border-radius: 20px;

    .app-header-nav-wrapper {
      display: flex;
      gap: 16px;
    }

    .app-header-nav-mobile-wrapper {
      display: flex;
      gap: 6px;
    }

    .connected {
      background-color: #262626;
    }

    @media (max-width: 1023px) {
      padding: 8px;
    }

    .app-header-nav-logo {
      display: flex;
      align-items: center;

      svg {
        height: 30px;
      }
    }

    .app-header-nav-list {
      width: 100%;
      max-width: min-content;

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0;

      :nth-child(3) {
        display: none;
      }

      li {
        display: flex;
        list-style-type: none;
        align-items: center;
        justify-content: center;

        // applies to NavLink
        a {
          width: 100%;
          height: 60px;
          padding: 0 16px;
          display: flex;
          align-items: center;
          text-decoration: none;
          font-size: 17px;
          line-height: 17px;
          letter-spacing: -0.22px;
          white-space: nowrap;
          font-family: "Inter";
          font-weight: 500;
          color: #b3b3b3;
          transition: var(--transition);

          &.active {
            color: #262626;
            font-weight: 500;
            text-shadow: 0.4px 0 #262626;

            &:hover {
              color: #262626;
            }
          }

          &:hover {
            color: #6b6b6b;
          }
        }
      }
    }

    .app-header-disconnected {
      height: 36px;
      padding: 0 16px;
      background-color: var(--cta-color);
      font-size: 18px;
      font-weight: 600;
      font-family: "Baloo 2";
      color: #fff;
      border-radius: 16px;
    }

    .app-header-connected {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      padding: 0 12px;
      height: 44px;

      border-radius: 14px;

      cursor: pointer;
      position: relative;

      background: #ffffff;
      border: #e6e6e6 solid 1px;

      svg {
        width: 24px;
        height: 24px;
      }

      .app-header-connected-address {
        color: #262626;
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        line-height: 15px; /* 100% */
        letter-spacing: -0.22px;
        font-weight: 500;
      }
    }
  }

  .app-header-nav-logo-list-wrapper {
    display: flex;
    gap: 16px;
  }

  .button-primary {
    width: 126px;
    height: 100%;
    padding: 8px 0;
    border-radius: 14px;
    font-size: 18px;

    background: rgb(27, 120, 231);
    background: linear-gradient(153deg, #1b78e7 0%, #2b9bc2 100%);
  }
}

.app-header-dropdown {
  position: absolute;
  top: 48px;
  background-color: #f4f4f4;
  border-radius: 10px;
  width: 100%;
  display: none;
  right: 0;

  &.open {
    display: block;
  }

  &-item {
    display: flex;
    gap: 8px;
    padding: 0 16px;
    height: 60px;
    align-items: center;

    &:first-child {
      border-bottom: 1px solid var(--quaternary-color);
    }
    p,
    a {
      text-decoration: none;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: -0.24px;
      white-space: nowrap;
      font-family: "Inter";
      font-weight: 500;
      color: var(--secondary-color);

      &.text-red {
        color: #eb004e;
      }
    }
  }
}

.app-header-mobile {
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 16px;

  .app-header-nav-logo svg {
    height: 30px;
  }

  &-nav {
    position: fixed;
    top: 0;
    right: -400px;

    height: 100vh;

    padding-bottom: 80px;

    // background: rgba(255, 255, 255, 0.9);

    display: flex;
    flex-direction: column;
    align-items: end;

    transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);

    z-index: 10;

    &.show {
      top: 0;
      right: 32px;
    }

    ul {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: center;

      gap: 16px;

      :nth-child(3) {
        display: none;
      }

      li {
        list-style-type: none;
        display: flex;
        justify-content: end;

        width: 100%;

        .mobile-nav-wrapper {
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 12px;
        }

        a {
          // flex: 1 1 0;

          text-align: right;

          padding: 16px 0;
          border-radius: 20px;

          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;

          text-decoration: none;
          color: #9b9b9b;

          transition: all 100ms ease-in-out;

          &.active {
            color: #ffffff;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;

            padding: 16px 36px;

            background: linear-gradient(130deg, #f20e7c 0%, #e24268 100%);
          }
        }
      }
    }

    &-button {
      justify-content: center;
      height: 50px;
    }
  }
}

.app-header-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9;
  background: #ffffffe5;
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.app-header-connected-container {
  display: flex;
  justify-content: flex-end;
  gap: 6px;

  .app-header-connected-network {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    height: 44px;
    padding: 0 12px;

    border-radius: 14px;
    background: #ffffff;

    border: #e6e6e6 solid 1px;

    .app-header-connected-network-name {
      color: var(--primary-color);
      text-align: center;

      font-family: Inter;
      font-size: 15px;
      font-weight: 500;
      line-height: 15px; /* 100% */
      letter-spacing: -0.13px;
    }
  }
}

.app-header-switch-network {
  display: flex;
  height: 100%;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  background: #ffffff;
  cursor: pointer;

  p {
    color: var(--primary-color);
    text-align: center;

    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 15px; /* 100% */
    letter-spacing: -0.13px;
  }
}

// For view-only landing page only---------------------------
.disable {
  display: none;
  pointer-events: none;
}
