// .lp-cta-container {
//   background: linear-gradient(#ac3edb, #262626 160%);
//   padding: 36px;
//   position: relative;
//   width: 100%;
//   max-width: 1440px;
//   margin: auto;
//   margin-top: 80px;
//   margin-bottom: 80px; // offset

//   .lp-section-header {
//     text-align: center;
//     margin-bottom: 36px;
//     gap: 16px;
//     max-width: 560px;
//     margin: auto;

//     .lp-heading {
//       color: #fff;
//     }
//   }
//   .lp-section-footer {
//     width: auto;
//   }

//   @media only screen and (min-width: 1440px) {
//     margin-bottom: 120px; // offset
//   }
// }

// .lp-cta-img-container {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;

//   .lp-cta-img {
//     position: absolute;
//     height: 180px;
//     bottom: -72px;
//     z-index: 2;

//     &.lp-cta-img-align-left {
//       left: -70px;
//     }
//     &.lp-cta-img-align-right {
//       right: -70px;
//     }

//     @media only screen and (min-width: 744px) {
//       height: 300px;

//       &.lp-cta-img-align-left {
//         left: -10%;
//       }
//       &.lp-cta-img-align-right {
//         right: -10%;
//       }
//     }

//     @media only screen and (min-width: 1440px) {
//       height: 630px;

//       &.lp-cta-img-align-left {
//         left: -320px;
//       }
//       &.lp-cta-img-align-right {
//         right: -320px;
//       }
//     }

//     @media only screen and (min-width: 1920px) {
//       height: 630px;

//       &.lp-cta-img-align-left {
//         left: -240px;
//       }
//       &.lp-cta-img-align-right {
//         right: -240px;
//       }
//     }
//   }
// }

.lp-cta-container {
  max-width: 100%;
  height: auto;

  // padding: 120px 0 320px;
  margin-bottom: 80px;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgb(255, 209, 139);
  background: linear-gradient(180deg, #f04dc4 0%, #fbfed0 74.75%, #fff 106.18%);

  // @media (max-width: 1200px) {
  //   padding-bottom: 280px;
  // }

  // @media (max-width: 768px) {
  //   padding-bottom: 240px;
  // }

  // @media (max-width: 430px) {
  //   padding-bottom: 180px;
  //   padding-top: 80px;
  // }

  .lp-cta-img-art {
    width: 100%;
  }

  .lp-section-header {
    width: calc(100% - 72px);
    max-width: 600px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    z-index: 2;

    margin-bottom: -25%;
    margin-top: 100px;

    @media (max-width: 768px) {
      margin-top: 60px;
    }

    @media (max-width: 430px) {
      width: calc(100% - 32px);
      margin-bottom: -13%;
      margin-top: 40px;
    }

    .lp-heading {
      width: 100%;
      max-width: 700px;

      text-align: center;

      color: #fff;
      text-align: center;
      font-family: Baloo;
      font-size: 62px;
      font-style: normal;
      font-weight: 400;
      line-height: 60px;
      letter-spacing: -0.93px;

      color: #ffffff;

      @media (max-width: 960px) {
        font-size: 52px;
        line-height: 52px;
      }

      @media (max-width: 768px) {
        font-size: 46px;
        line-height: 46px;
      }

      @media (max-width: 430px) {
        font-size: 32px;
        line-height: 32px;
      }
    }

    p {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      font-weight: 500;

      max-width: 600px;

      @media (max-width: 430px) {
        font-size: 15px;
        line-height: 22px;
      }
    }

    a {
      z-index: 1;

      text-decoration: none;
      -webkit-text-decoration-line: none;

      .lp-cta-bottom-btn {
        width: 160px;
        height: 52px;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        padding: 0px 16px;

        background-color: #ffffff;

        border-radius: 16px;

        color: #262626;
        text-align: center;
        font-family: "Baloo 2";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.46px;

        transition: all 0.3s ease;

        &:hover {
          background-color: #1f072b;
          color: #ffc56e;
        }
      }
    }
  }
}
