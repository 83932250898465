.lp-team-body {
  width: 100%;

  margin: 160px 0 0;

  display: flex;
  justify-content: center;

  @media (max-width: 430px) {
    margin: 120px 0 0;
  }

  .lp-team-wrapper {
    width: calc(100% - 72px);
    max-width: 1200px;

    display: flex;
    flex-direction: column;
    gap: 80px;

    @media (max-width: 430px) {
      gap: 40px;

      width: calc(100% - 32px);
    }

    .lp-team-container {
      display: flex;
      flex-direction: column;
      gap: 40px;

      @media (max-width: 430px) {
        gap: 30px;
      }

      .lp-team-category {
        display: flex;
        align-items: center;
        gap: 16px;

        @media (max-width: 430px) {
          gap: 12px;
        }

        .lp-team-category-icon {
          width: 100%;
          max-width: 44px;

          @media (max-width: 430px) {
            max-width: 32px;
          }
        }

        // #lp-team-founder-title {
        //   color: #ac3edb;
        //   font-family: Baloo;
        //   font-size: 36px;
        //   font-style: normal;
        //   font-weight: 400;
        //   line-height: 36px;
        //   letter-spacing: -0.93px;

        //   @media (max-width: 430px) {
        //     font-size: 30px;
        //     line-height: 30px;
        //   }
        // }

        .lp-team-core-title {
          font-family: Baloo;
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: 36px; /* 100% */
          letter-spacing: -0.93px;

          @media (max-width: 430px) {
            font-size: 30px;
            line-height: 30px;
          }
        }

        .founder {
          color: #6e3edb;
        }

        .tech {
          color: #ac3edb;
        }

        .creative {
          color: #db3ebc;
        }

        .operations {
          color: #c8762b;
        }
      }
    }

    .lp-team-introduction {
      width: 100%;
      max-width: 600px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 36px;

      @media (max-width: 430px) {
        flex-direction: column;
        gap: 16px;
      }

      .lp-team-introduction-title {
        color: #262626;
        font-family: Baloo;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.8px;

        @media (max-width: 430px) {
          font-size: 26px;
          line-height: 24px;
        }
      }

      .lp-team-introduction-description {
        width: 100%;

        color: #262626;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: -0.18px;

        @media (max-width: 430px) {
          font-size: 15px;
          line-height: 21px;
        }
      }
    }
  }
}
