.button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  border-radius: 16px;
  text-align: center;
  font-family: "Baloo 2";
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.46px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &-primary {
    padding: 16px 24px;
    background-color: #262626;
    color: #fff;
    min-width: 100px;
  }
  &-secondary {
    padding: 16px 24px;
    background-color: #dbdbdb;
    color: #262626;
    min-width: 100px;
  }
  &-white {
    padding: 16px 24px;
    background-color: #fff;
    color: #262626;
    min-width: 100px;
  }
  &-rounded {
    border-radius: 26px;
  }
}
