

.raffle-rules {
  width: 100%;
  max-width: 446px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 24px;

  .raffle-rules-header {
    width: 100%;
    max-width: 398px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    .raffle-rules-heading-text {
      color: var(--primary-color);
      text-align: center;

      font-family: Inter;
      font-size: 22px;
      font-weight: 600;
      line-height: 22px; /* 100% */
      letter-spacing: -0.4px;
    }

    .raffle-rules-heading-close {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 24px;
    }
  }

  .raffle-rules-list {
    padding-left: 24px;

    li {
      list-style-type: auto;
      color: var(--primary-color);

      font-family: Inter;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.13px;
    }
  }

  .raffle-rules-confirm-button {
    display: flex;
    height: 56px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: var(--primary-color);
    margin-top: 36px;

    span {
      color: #FFF;
      text-align: center;
      font-family: "Baloo 2";
      font-size: 18px;
      font-weight: 600;
      line-height: 18px; /* 100% */
      letter-spacing: -0.46px;
    }
  }

  @media only screen and (max-width: 430px) {
    padding: 16px;
    border-radius: 26px;
    max-width: 398px;

    .raffle-rules-header {
      margin-bottom: 30px;
  
      .raffle-rules-heading-text {
        font-size: 22px;
        font-weight: 600;
        line-height: 22px; /* 100% */
        letter-spacing: -0.4px;

      }
  
      .raffle-rules-heading-close {
        position: absolute;
        right: 16px;
      }
    }
  
    .raffle-rules-list {
      padding-left: 16px;
  
      li {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.13px;
      }
    }
  
    .raffle-rules-confirm-button {
      margin-top: 36px;
    }
  }
    
  @media only screen and (max-width: 320px) {
    padding: 16px;
    border-radius: 26px;
    max-width: 288px;

    .raffle-rules-header {
      margin-bottom: 16px;
  
      .raffle-rules-heading-text {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px; /* 100% */
        letter-spacing: -0.26px;
      }
  
      .raffle-rules-heading-close {
        position: absolute;
        right: 16px;
      }
    }
  
    .raffle-rules-list {
      padding-left: 16px;
  
      li {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.04px;
      }
    }
  
    .raffle-rules-confirm-button {
      margin-top: 24px;
    }
  }
}

.roll-options {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  
  margin: 0 auto;
  border-radius: 32px;
  background: var(--quinary-color);
  z-index: 1;

  .roll-options-chances {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .roll-options-chances-text {
      display: flex;
      align-items: center;

      .roll-options-chances-label {
        color: var(--secondary-color);
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px; /* 100% */
        letter-spacing: -0.26px;
      }

      .roll-options-chances-value {
        color: var(--primary-color);
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.26px;
      }
    }

    .roll-options-chances-countdown {
    
      .roll-options-chances-countdown-label {
        color: var(--secondary-color);
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px; /* 100% */
        letter-spacing: -0.26px;
      }

      .roll-options-chances-countdown-time {
        color: var(--primary-color);
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.26px;
      }
    }
  }

  .roll-options-button {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 16px;

    .roll-options-button-multiple {
      display: flex;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;

      border-radius: 16px;
      border: 0.5px solid var(--primary-color);
      background: var(--primary-color);

      .roll-options-button-multiple-text {
        color: #FFF;
        text-align: center;
        font-family: "Baloo 2";
        font-size: 20px;
        font-weight: 600;
        line-height: 20px; /* 100% */
        letter-spacing: -0.46px;
      }
    }

    .disabled {
      opacity: 0.3;
    }
  }

  @media only screen and (max-width: 744px) {

  }

  @media only screen and (max-width: 430px) {
    padding: 24px;
    border-radius: 32px;

    .roll-options-chances {
      
      .roll-options-chances-text {
        .roll-options-chances-label {
          font-size: 15px;
          font-weight: 600;
          line-height: 15px; /* 100% */
          letter-spacing: -0.13px;
        }
        .roll-options-chances-value {
          font-size: 15px;
          font-weight: 600;
          line-height: 15px;
          letter-spacing: -0.13px;
        }
      }

      .roll-options-chances-countdown {
        .roll-options-chances-countdown-label{
          font-size: 15px;
          font-weight: 600;
          line-height: 15px; /* 100% */
          letter-spacing: -0.13px;
        }
        .roll-options-chances-countdown-time {
          font-size: 15px;
          font-weight: 600;
          line-height: 15px;
          letter-spacing: -0.13px;
        }
      }
    }

    .roll-options-button {

      .roll-options-button-multiple {
        .roll-options-button-multiple-text{
          font-size: 18px;
          font-weight: 600;
          line-height: 18px; /* 100% */
          letter-spacing: -0.46px;
        }
      }
    }
  }

  @media only screen and (max-width: 320px) {
    padding: 16px;
    border-radius: 16px;

    .roll-options-chances {
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
    }
  }
}