.lp-banner-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 20px;
  border: 1px solid #e5e5e5;
  background: linear-gradient(90deg, #6109ad 0%, #ac3edb 100%);
  width: 100%;
  gap: 24px;

  overflow: hidden;

  .lp-section-header {
    margin-bottom: 0;
    gap: 12px;

    width: 60%;

    margin-right: auto;

    .lp-heading {
      letter-spacing: 0px;
    }

    .lp-heading,
    .text {
      color: #fff;
    }
  }
  .lp-section-footer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    z-index: 1;
  }

  @media only screen and (min-width: 744px) {
    padding: 48px 42px 54px;
    align-items: flex-start;
    gap: 32px;

    .lp-section-header {
      h1 {
        max-width: 86%;
      }
    }
  }
}

.lp-banner-icon-container {
  position: absolute;
  display: flex;
  width: 560px;
  top: 0;
  bottom: 0;
  right: -100px;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1080px) {
    right: -180px;
  }

  @media (max-width: 768px) {
    right: -160px;
  }

  @media (max-width: 430px) {
    right: -200px;
  }

  img {
    width: 560px;

    @media (max-width: 768px) {
      width: 460px;
    }

    @media (max-width: 430px) {
    }
  }
}
